import {Component, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {NgForm} from '@angular/forms';
import {Subscription} from 'rxjs';
import {Four51Service} from '../four51.service';
import {NgbDate, NgbDateParserFormatter, NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {ActivatedRoute, ParamMap, Router} from '@angular/router';
import {SpecService} from '../spec.service';
import * as moment from 'moment';
import {NgbDateCustomParserFormatter} from '../dateformat';
import {MailerMessage, MailerService} from '../mailer.service';
import {MailTemplateService, MailTemplateValue} from '../mail-template.service';
import {CookieService} from 'ngx-cookie-service';
import {environment} from '../../environments/environment';
import { ApiService } from '../api.service';

@Component({
  selector: 'app-membership-giveaway',
  templateUrl: './membership-giveaway.component.html',
  styleUrls: ['./membership-giveaway.component.scss'],
  providers: [
    {provide: NgbDateParserFormatter, useClass: NgbDateCustomParserFormatter}
  ]
})

export class MembershipGiveawayComponent implements OnInit, OnDestroy  {
  private variant;
  private subscription: Subscription;
  private user: any;
  private imageUrl;
  private proofUrl;
  private isWaiting: boolean;
  public isComplete = false;
  private hidden = ['V02Purpose', 'V00Event_Name', 'Date', 'Date10', 'Date30', 'data'];
  private minEventDate: NgbDate;
  private minDeliverDate: NgbDate;
  private eventDate: NgbDate;
  private deliverDate: NgbDate;
  private productId;
  private shipAddress: any;
  private comments: string;
  constructor(private four51Service: Four51Service, private modalService: NgbModal,
              private route: ActivatedRoute, private router: Router, private specService: SpecService,
              private mailerService: MailerService,
              private mailTemplateService: MailTemplateService, private cookieService: CookieService,
              private apiService: ApiService) {}
  async ngOnInit() {
    this.subscription = new Subscription();
    const paramMapSubscription = this.route.paramMap.subscribe((params: ParamMap) => {
      const step = params.get('step');
      const complete = params.get('complete');
      if (complete) {
        this.isComplete = true;
      } else {
        this.isComplete = false;
      }
    });
    this.subscription.add(paramMapSubscription);

    const minEventDateMoment = moment().add(2, 'weeks');
    this.minEventDate = new NgbDate(minEventDateMoment.year(), minEventDateMoment.month() + 1, minEventDateMoment.date());
    const minDeliverDateMoment = moment();
    let businessDays = 7;
    do {
      minDeliverDateMoment.add(1, 'days');
      if (minDeliverDateMoment.isoWeekday() < 6) {
        businessDays--;
      }
    } while (businessDays > 0);
    this.minDeliverDate = new NgbDate(minDeliverDateMoment.year(), minDeliverDateMoment.month() + 1, minDeliverDateMoment.date());
    this.productId = 'A678D8E2-AD56-4B10-BB5B-08142796E227';
    const product: any = await this.four51Service.getProduct(this.productId).toPromise();
    console.log(product.Specs);
    this.variant = {
      ProductInteropID: this.productId,
      Specs: await this.specService.fillInUserData(product.Specs)
    };
    this.user = await this.apiService.getUser().toPromise();
    this.getShipAddress();
    this.updateVariant();
  }
  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }
  async updateVariant() {
    this.isWaiting = true;
    this.variant.Specs.Date.Value = moment(this.ngbDateToString(this.eventDate), 'YYYY-MM-DD').format('MM/DD/YYYY');
    this.variant.Specs.Date10.Value = moment(this.ngbDateToString(this.deliverDate), 'YYYY-MM-DD').format('MM/DD/YYYY');
    this.variant.Specs.Date30.Value = moment(this.ngbDateToString(this.eventDate), 'YYYY-MM-DD').add(30, 'days')
      .format('MMMM D, YYYY');
    this.variant.Specs.V02Purpose.Value = 'Auction';
    const data = {
      comments: this.comments,
      physicianId: this.user.physician ? this.user.physician.physicianId : '',
      email: this.user.physician ? this.user.physician.email : '',
      pdmEmail: this.user.physician ? this.user.physician.pdmEmail : '',
      ptmEmail: this.user.physician ? this.user.physician.ptmEmail : '',
      staffEmail: this.user.physician ? this.user.physician.staffEmail : '',
      practiceStatus: this.user.physician ? this.user.physician.practiceStatus : '',
      relationshipStatus: this.user.physician ? this.user.physician.relationshipStatus : '',
      physicianStatusType: this.user.physician ? this.user.physician.physicianStatusType : '',
      channel: this.cookieService.get('mdvip.channel')
    }
    this.variant.Specs.data.Value = JSON.stringify(data);
    const response = await this.four51Service.createVariant(this.variant).toPromise();
    this.variant = response.body;
    console.log(this.variant);
    this.imageUrl = this.variant.PreviewUrl + '?r=' + Math.random();
    this.proofUrl = this.variant.ProofUrl + '?r=' + Math.random();
  }

  onPreviewLoaded() {
    this.isWaiting = false;
  }

  onPreviewError(e) {
    console.log(e);
    this.isWaiting = false;
  }

  async getShipAddress() {
    if (this.user && this.user.physician) {
      this.shipAddress = {
        CompanyName: this.user.physician.fullName || '',
        Street1: this.user.physician.address1 || '',
        Street2: this.user.physician.address2 || '',
        City: this.user.physician.city || '',
        State: this.user.physician.state || '',
        Zip: this.user.physician.zip || '',
        Country: 'US',
        Phone: this.user.physician.phone || '',
        FirstName: this.user.physician ? 'Dr.' : '',
        LastName: this.user.physician.lastname || '',
      } 
    } else {
      this.shipAddress = {
        CompanyName: '',
        Street1: '',
        Street2: '',
        City: '',
        State: '',
        Zip: '',
        Country: 'US',
        Phone: '',
        FirstName: '',
        LastName: '',
      }
    }
  }

  async onMembershipGiveawayFormSubmit(membershipGiveawayForm: NgForm) {
    if (membershipGiveawayForm.valid) {
      await this.updateVariant();

      const propagoOrder = {
        signatureRequired: false,
        isExpedited: false,
        promoCode: '',
        invoiceEmail: '',
        mailInvoice: false,
        username: this.user.username,
        orderTag: '',
        comments: (!environment.production || environment.isTest) ? 'TEST-EVENT-SITE': '',
        alternativeEmail: '',
        carrier: 'FedEx - Ground',
        desiredShipDate: moment().add(1,'d').startOf('d').format('YYYY-MM-DDThh:mm:ssZ'), //'2024-12-11T05:00:00Z',
        shippingAddress: {
          contact: `${this.shipAddress.FirstName} ${this.shipAddress.LastName}`,
          company: this.shipAddress.CompanyName,
          address1: this.shipAddress.Street1,
          address2: this.shipAddress.Street2,
          address3: '',
          city: this.shipAddress.City,
          state: this.shipAddress.State,
          country: 'US',
          zip: this.shipAddress.Zip,
          externalId: '',
          phone: this.shipAddress.Phone,
          email: this.user.physician ? this.user.physician.email : 'marketingsupportsite@mdvip.com',
          isResidential: false,
          isSignatureRequired: false
        },
        accountingUnit: '',
        billingAddress: {
          company: 'MDVIP',
          contact: 'Accounts Payable',
          address1: '4950 Communication Ave.',
          address2: 'Suite 100',
          city: 'Boca Raton',
          state: 'FL',
          country: 'US',
          zip: '33431',
          externalId: ''
        },
        placeOrderLines: [
          {
            sku: 'MD.52424',
            quantity: 1,
            printFile: this.variant.ProofUrl.replace('/PDF/', '/PDFO/'),
            assetId: this.variant.InteropID,
            productId: this.productId,
            name: 'Personalized Gift Tag (Auction Gift Site)'
          },
          {
            sku: 'MD.52425',
            quantity: 1,
            printFile: '',
            assetId: null,
            productId: 'E95E0413-3AA1-4B6D-B404-CACF3DDF0862',
            name: 'Kitted Bags (Auction Gift Site)'
          }
        ],
        orderField1: '', //JSON.stringify(this.eventInfo),
        orderField2: '',
        orderField3: '',
        orderField4: '',
        orderField5: this.user.username,
        poNumber: '',
        verifyPONumber: false,
        thirdPartyAccountNumber: '',
      };

      const submitted = moment();
      const pendingOrder = {
        username: propagoOrder.username,
        status: 'Pending',
        type: 'Membership Giveaway',
        submitted: submitted.format('YYYY-MM-DDTHH:mm:ss') + 'Z',
        eventInfo: {
          comments: this.comments,
          physicianId: this.user.physician ? this.user.physician.physicianId : '',
          email: this.user.physician ? this.user.physician.email : '',
          pdmEmail: this.user.physician ? this.user.physician.pdmEmail : '',
          ptmEmail: this.user.physician ? this.user.physician.ptmEmail : '',
          staffEmail: this.user.physician ? this.user.physician.staffEmail : '',
          practiceStatus: this.user.physician ? this.user.physician.practiceStatus : '',
          relationshipStatus: this.user.physician ? this.user.physician.relationshipStatus : '',
          physicianStatusType: this.user.physician ? this.user.physician.physicianStatusType : '',
          channel: this.cookieService.get('mdvip.channel')
        },
        order: propagoOrder,
        secondOrder: null
      }
      const pendingResponse = await this.apiService.createPendingOrder(pendingOrder).toPromise();

      this.router.navigate(['/membership-giveaway', {complete: true}]);

      /*
       * MG Submitted Internal Email
       */
      const mgSubmittedInternalEmail: MailerMessage = new MailerMessage();
      mgSubmittedInternalEmail.Subject = 'FOR REVIEW: Auction Gift ' + (this.user.physician ? this.user.physician.fullName : '') + ' ' +
        this.variant.Specs.Date.Value;
      mgSubmittedInternalEmail.Bcc = environment.emailBcc;
      if (!environment.production || environment.isTest) {
        mgSubmittedInternalEmail.To = environment.emailTo;
        mgSubmittedInternalEmail.Subject = 'TESTING: ' + mgSubmittedInternalEmail.Subject;
      } else {
        mgSubmittedInternalEmail.To = ['Marketing Support Site <MarketingSupportSite@mdvip.com>'];
        mgSubmittedInternalEmail.To.push('pdmadmin@mdvip.com');
      }
      mgSubmittedInternalEmail.From = 'Marketing Support Site <MarketingSupportSite@mdvip.com>';
      mgSubmittedInternalEmail.Body = 'This message requires an email client that supports HTML email.';
      const approval_url = window.location.origin + '/approval';
      const mailTemplateValues: MailTemplateValue[] = [
        {name: 'fullname', value: this.variant.Specs.V03Full_Name.Value},
        {name: 'event_name', value: this.variant.Specs.V00Event_Name.Value},
        {name: 'event_date', value: this.variant.Specs.Date.Value},
        {name: 'deliver_date', value: this.variant.Specs.Date10.Value},
        {name: 'comment', value: this.variant.Specs.Date30.Value},
        {name: 'approval_url', value: approval_url}
      ];
      mgSubmittedInternalEmail.Html = await this.mailTemplateService
        .replaceValues('./assets/html/mgSubmittedInternal.html', mailTemplateValues);
      this.mailerService.sendMessage(mgSubmittedInternalEmail);
    }
  }

  ngbDateToString(object: NgbDate) {
    return (object) ? object.year + '-' + object.month + '-' + object.day : '';
  }
}
