import {Injectable, OnDestroy} from '@angular/core';
import * as moment from 'moment';

import {Subscription} from 'rxjs';
import {CartService} from './cart.service';
import { ApiService } from './api.service';

@Injectable({
  providedIn: 'root'
})
export class OrderLimitService implements OnDestroy {

  private subscription: Subscription;
  private cart: any;
  constructor(
    private apiService: ApiService,
    private cartService: CartService
  ) {
    this.subscription = new Subscription();
  
    const cartSubscription = this.cartService.cartObservable().subscribe(cart => {
      this.cart = cart;
    });
    this.subscription.add(cartSubscription);
  }
  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }
  async getOrderLimit(request: OrderLimitRequest): Promise<OrderLimitResponse> {
    const user = await this.apiService.getUser().toPromise();
    let physician = null;
    if (user && user.physician) {
      physician = user.physician;
    }
    const requestQuantity = +request.quantity; // force numeric value
    let canOrder = true;
    let message = '';
    switch (request.sku) {

      case 'MD.64540': {  // Wellness Program Folder
        canOrder = true;
        message = '';
        const endDate = moment();
        const quarterHistory = await this.getHistory(moment().startOf('quarter'), endDate, request.sku);
        if ((quarterHistory + requestQuantity) > 200) { // check the shorter query first
          canOrder = false;
          message = 'You have reached your limit of 200 folders per quarter. Please contact Order Support for any questions.';
        } else {
          const thisYear = endDate.year();
          const startDate = moment({month: 6, day: 1, year: thisYear} );
          if (startDate.isAfter(endDate)) {
            startDate.add(-1, 'year');
          }
          const propagoHistory = await this.getHistory(startDate, endDate, request.sku);
          const totalMembers = physician.totalMembers ? +physician.totalMembers : 1;
          let roundedMembers = 0;
          if (physician.practiceStatus.toLowerCase() === 'post-open') {
            roundedMembers = Math.ceil(totalMembers / 50) * 50;
          } else {
            roundedMembers = 100;
          }
          console.log('propagoHistory: ' + propagoHistory + '; totalMembers: ' + totalMembers);
          if ((propagoHistory + requestQuantity) > roundedMembers) {
            canOrder = false;
            message = 'You have reached your limit of ' + roundedMembers + ' folders. Please contact Order Support for any questions.';
          }
        }
        break;
      }

      case 'MD.61367':   // Pre-Wellness Checklist
      case 'MD.64528': { // Pre-Wellness Plus Checklist
        canOrder = true;
        message = '';
        const endDate = moment();
        const thisYear = endDate.year();
        const startDate = moment({month: 6, day: 1, year: thisYear} );
        if (startDate.isAfter(endDate)) {
          startDate.add(-1, 'year');
        }
        const limit = 700;
        const history = await this.getHistory(startDate, endDate, request.sku);
        if (history + requestQuantity > limit) {
          canOrder = false;
          message = 'You have reached your limit of ' + limit + ' ' + request.sku +
            's.<br />Please contact Order Support for any questions.';
        }
        break;
      }

      case 'MD.64554': { // Wellness Appointment Postcard
        const productName = 'Wellness Appointment Postcard';
        canOrder = true;
        message = '';
        const endDate = moment();
        const thisYear = endDate.year();
        const startDate = moment({month: 6, day: 1, year: thisYear} );
        if (startDate.isAfter(endDate)) {
          startDate.add(-1, 'year');
        }
        const limit = 300;
        const history = await this.getHistory(startDate, endDate, request.sku);
        if (history + requestQuantity > limit) {
          canOrder = false;
          message = 'You have reached your limit of ' + limit + ' ' + productName +
            's.<br />Please contact Order Support for any questions.';
        }
        break;
      }

      case 'MD.64682-PP': { // 2024 Birthday Card
        canOrder = true;
        message = '';
        const endDate = moment();
        const thisYear = endDate.year();
        const startDate = moment({month: 6, day: 1, year: thisYear} );
        if (startDate.isAfter(endDate)) {
          startDate.add(-1, 'year');
        }
        const four51history = await this.getHistory(startDate, endDate, request.sku);
        const profileData = {};
        const totalMembers = (profileData['totalMembers']) ?
          +profileData['totalMembers'] : 1;
        const roundedMembers = Math.ceil(totalMembers / 100) * 100;
        console.log(`four51history: ${four51history}; totalMembers: ${totalMembers}`);
        if ((four51history + requestQuantity) > roundedMembers) {
          canOrder = false;
          message = 'You have reached your limit of ' + roundedMembers + ' cards. Please contact Order Support for any questions.';
        }
        break;
      }

      default: {
        canOrder = true;
        message = '';
        const part = await this.apiService.getPart(request.sku).toPromise();
        if (part.itemType === 'Pick and Pack') {
          const partInventory = await this.apiService.getPartInventory(request.sku).toPromise();
          const qtyAvailable = partInventory.qtyAvailable || 0;
          if (requestQuantity > qtyAvailable) {
            canOrder = false;
            message = 'There are currently only ' + qtyAvailable + ' ' + part.partName +
              's available.<br />Please contact Order Support for any questions.';
          }
        }
        break;
      }
    }
    return {
      canOrder: canOrder,
      message: message
    };
  }

  async getHistory(startDate: moment.Moment, endDate: moment.Moment, sku: string) {
    let ordered = 0;

    const orderLineSum = (sum: number , orderLine: any) => {
      if ((orderLine.sku || orderLine.part.displaySku) === sku) {
        return sum + orderLine.quantity;
      } else {
        return sum;
      }
    };

    const statuses = [
      // 'All',
      'AwaitingShipment',
      'OnHold',
      'Closed',
      // 'ApprovalDenied',
      'PreClose',
      'Firmed',
      'AwaitingSerialCapture',
      'PaymentFailed',
      'Invoiced',
      'Consolidated',
      'Processing',
      'Unknown',
      'BatchProcessing', 
      'QuickShip',
      'Picking',
      'AwaitingPaymentAuthorization',
      'Released',
      'BackOrdered',
      'Waved',
      'FastPickPackNotPrinted',
      'Saved',
      'ApprovalPending',
      // 'Canceled',
      'FastPickPack',
      'Shipping'
    ];
    
    const allOrders = await this.apiService.searchOrders(startDate, endDate).toPromise();
    const orders: any = allOrders.filter((x: any) => statuses.includes(x.status));
    for (const order of orders) {
      ordered += order.orderLines.reduce(orderLineSum, 0);
    }
    if (this.cart) {
      ordered += this.cart.placeOrderLines.reduce(orderLineSum, 0);
    }
    console.log(ordered);
    return ordered;
  }

}
export interface OrderLimitResponse {
  canOrder: boolean;
  message: string;
}
export interface OrderLimitRequest {
  sku: string;
  quantity: number;
}
