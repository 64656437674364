import {Injectable} from '@angular/core';
import {MaskPipe} from 'ngx-mask';
import { ApiService } from './api.service';

@Injectable({
  providedIn: 'root'
})

export class SpecService {
  constructor(private maskPipe: MaskPipe, private apiService: ApiService) {}

  public async fillInUserData(specs: any) {
    const user = await this.apiService.getUser().toPromise();
    if (user && user.physician) {
      for (const key of Object.keys(specs)) {
        const identifier = this.getSpecLabel(specs[key]).toLowerCase().replace(/(\s|')/g, '');
        switch (identifier) {
          case 'fullname':
          case 'doctorsfullname': {
            specs[key].Value = user.physician.fullName;
            break;
          }
          case 'lastname':
          case 'doctorslastname': {
            specs[key].Value = user.physician.lastname;
            break;
          }
          case 'specialty':
          case 'doctorsspecialty': {
            specs[key].Value = user.physician.specialty;
            break;
          }
          case 'address':
          case 'address1':
          case 'doctorsaddress1': {
            specs[key].Value = user.physician.address1;
            break;
          }
          case 'address2':
          case 'doctorsaddress2': {
            specs[key].Value = user.physician.address2;
            break;
          }
          case 'city':
          case 'doctorscity': {
            specs[key].Value = user.physician.city;
            break;
          }
          case 'state':
          case 'doctorsstate': {
            specs[key].Value = user.physician.state;
            break;
          }
          case 'zip':
          case 'doctorszip': {
            specs[key].Value = user.physician.zip;
            break;
          }
          case 'phone':
          case 'doctorsphonenumber': {
            specs[key].Value = this.maskPipe.transform(user.physician.phone, '000.000.0000');
            break;
          }
          case 'fax': {
            specs[key].Value = this.maskPipe.transform(user.physician.fax, '000.000.0000');
            break;
          }
          case 'website':
          case 'doctorswebpage': {
            specs[key].Value = user.physician.website;
            break;
          }
          case 'facebook': {
            specs[key].Value = user.physician.facebook;
            break;
          }
          case 'doctorspicture': {
            specs[key].Value = `pe${user.physician.lastname}${user.physician.physicianId}.jpg`;
            break;
          }
          case 'certifications': {
            specs[key].Value = this.replaceHtml(user.physician.certifications || '');
            break;
          }
          case 'medicalschool': {
            specs[key].Value = this.replaceHtml(user.physician.medicalSchool || '');
            break;
          }
          case 'hospitalaffiliations': {
            specs[key].Value = this.replaceHtml(user.physician.hospitalAffiliations || '');
            break;
          }
        }
      }
    }
    return specs;
  }

  getSpecLabel(spec: any) {
    if (spec.Label) {
      return spec.Label;
    } else {
      return '';
    }
  }

  replaceHtml(string: string): string {
    return string
      .replace(/<br\s?\/?>/g, '\n')
      .replace(/&#(\d+);/g, (match, dec) => {
        return String.fromCharCode(dec);
      });
  }

}
