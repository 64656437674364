import {Injectable} from '@angular/core';
import {MailerMessage} from './mailer.service';

@Injectable({
  providedIn: 'root'
})
export class PhysicianEmailAddrService {
  setAddresses(message: MailerMessage, doctor, staff, ptm, pdm,
               practiceStatus, relationshipStatus, physicianStatusType) {
    // To
    if (!message.To) {
      message.To = [];
    }
    if (doctor) {
      message.To.push(doctor);
    }
    if (staff) {
      message.To.push(staff);
    }
    if (relationshipStatus === 'MDVIP Employed Affiliate') {
      message.To.push('OwnedPracticeOps@mdvip.com');
    }
    if (physicianStatusType && physicianStatusType.toLowerCase() === 'separation in progress') {
      message.To.push('SuccessionEvents@mdvip.com');
    }
    // Cc
    if (!message.Cc) {
      message.Cc = [];
    }
    message.Cc.push('pdmadmin@mdvip.com');
    if (ptm && (practiceStatus === 'Transition' || practiceStatus === 'Pre-Open')) {
      message.Cc.push(ptm);
    }
    if (pdm && (practiceStatus === 'Pre-Open' || practiceStatus === 'Post-Open')) {
      message.Cc.push(pdm);
    }
    // Bcc
    if (!message.Bcc) {
      message.Bcc = [];
    }
    message.Bcc.push('marketingsupportsite@mdvip.com');
  }
}
