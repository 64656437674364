import { Injectable } from '@angular/core';
import { CookieService } from 'ngx-cookie-service';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class AuthService {
  private token: string;

  private isAuthenticatedSubject = new BehaviorSubject<boolean>(false);
  public authState = this.isAuthenticatedSubject.asObservable();

  constructor(private cookieService: CookieService) {
    if (this.cookieService.check('mdvip.jwt')) {
      this.token = this.cookieService.get('mdvip.jwt');
      this.isAuthenticatedSubject.next(true);
    }
  }

  // isAuthenticated(): boolean {
  //   // Add logic to check if the token is valid (e.g., not expired)
  //   return !!this.token;
  // }

  getToken(): string {
    return this.token;
  }

  setToken(token?: string): void {
    this.token = token;
    if (token) {
        this.cookieService.set('mdvip.jwt', token);
        this.isAuthenticatedSubject.next(true);
    } else {
        this.cookieService.delete('mdvip.jwt', '/');
        this.isAuthenticatedSubject.next(false);
    }
  }
}