import { Injectable } from '@angular/core';
import { CanActivate, Router } from '@angular/router';
import { AuthService } from './auth.service';

@Injectable({
  providedIn: 'root'
})
export class LoginGuard implements CanActivate {
  private isAuthenticated = false;
  constructor(
    private authService: AuthService, 
    private router: Router
  ) {
    this.authService.authState.subscribe(
      (isAuthenticated) => {
        this.isAuthenticated = isAuthenticated;
        if (isAuthenticated) {
          this.router.navigate(['/home']);
        }
      }
    );
  }

  canActivate(): boolean {
      if (this.isAuthenticated) {
        this.router.navigate(['/home']);
        return false;
      } else {
        return true;
      }
    }
}