import {Injectable, OnDestroy} from '@angular/core';
import {BehaviorSubject, Observable, Subject} from 'rxjs';
import { ApiService } from './api.service';
import { AuthService } from './auth.service';
import { takeUntil } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class CartService implements OnDestroy {
  private cart: any;
  private cartSubject: BehaviorSubject<any> = new BehaviorSubject(null);
  private destory$ = new Subject<void>();
  public cartObservable(): Observable<any> {
    return this.cartSubject.asObservable();
  }
  constructor(
    protected apiService: ApiService,
    private authService: AuthService
  ) {
    this.authService.authState
      .pipe(takeUntil(this.destory$))
      .subscribe(
        (isAuthenticated) => {
          this.checkCart()
        }
      );
  }
  async checkCart() {
    this.cart = null;
    const response: any = await this.apiService.getCart().toPromise();
    if (response) {
      this.cart = response[response.length - 1];
    }
    this.cartSubject.next(this.cart);
  }

  async addToCart(productId: string, propago: any, quantity: number, printFile: string, assetId: string) {

    const lineItem = {
      productId,
      name: propago.part.partName,
      sku: propago.part.displaySku,
      quantity,
      quantities: propago.partPriceBreaks.map(x => x.quantity),
      printFile,
      assetId
    };
    if (this.cart) {
      this.cart.placeOrderLines.push(lineItem);
    } else {
      this.cart = {
        placeOrderLines: [lineItem],
        approvals: []
      };
    }
    this.saveCart();
  }

  async removeFromCart(index: number) {
    this.cart.placeOrderLines.splice(index, 1);
    this.saveCart();
  }

  async saveCart() {
    if (this.cart.placeOrderLines.length > 0) { 
      this.cart = await this.apiService.createOrUpdateCart(this.cart).toPromise();
    } else {
      await this.apiService.deleteCart(this.cart.id).toPromise();
      this.cart = null;
    }
    this.cartSubject.next(this.cart);
  }

  ngOnDestroy() {
    this.destory$.next();
    this.destory$.complete();
  }

}
