import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {HomeComponent} from './home/home.component';
import {LoginComponent} from './login/login.component';
import {EventsComponent} from './events/events.component';
import {HistoryComponent} from './history/history.component';
import {EventApprovalComponent} from './events/event-approval.component';

import {CartComponent} from './cart/cart.component';
import {PowerpointLibraryComponent} from './powerpoint-library/powerpoint-library.component';
import {BannersComponent} from './banners/banners.component';
import {MembershipGiveawayComponent} from './membership-giveaway/membership-giveaway.component';
import {CrmReportComponent} from './crm-report/crm-report.component';
import {EventSiteComponent} from './event-site/event-site.component';
import {LookupComponent} from './history/lookup.component';
import { AuthGuard } from './auth.guard';
import { LoginGuard } from './login.guard';

const routes: Routes = [
  {path: '', redirectTo: '/login', pathMatch: 'full'},
  {path: '', component: EventSiteComponent,
    children: [
      { path: 'home', component: HomeComponent, canActivate: [AuthGuard] },
      { path: 'home/:categoryid', component: HomeComponent, canActivate: [AuthGuard] },
      { path: 'events', component: EventsComponent, canActivate: [AuthGuard] },
      { path: 'events/:type', component: EventsComponent, canActivate: [AuthGuard] },
      { path: 'login', component: LoginComponent, canActivate: [LoginGuard] },
      { path: 'history', component: HistoryComponent, canActivate: [AuthGuard]},
      { path: 'cart', component: CartComponent, canActivate: [AuthGuard]},
      { path: 'banners', component: BannersComponent, canActivate: [AuthGuard]},
      { path: 'membership-giveaway', component: MembershipGiveawayComponent, canActivate: [AuthGuard]},
      { path: 'powerpoint-library', component: PowerpointLibraryComponent, canActivate: [AuthGuard]},
      { path: 'approval', component: EventApprovalComponent, canActivate: [AuthGuard]}, // Approval Guard
      { path: 'crm-report', component: CrmReportComponent, canActivate: [AuthGuard]}, // Approval Guard
      { path: 'lookup', component: LookupComponent, canActivate: [AuthGuard]}, // Approval Guard
    ]
  },
  { path: 'nameplate', loadChildren: () => import('./nameplate/nameplate.module').then(m => m.NameplateModule)}
];

@NgModule({
  imports: [ RouterModule.forRoot(routes) ],
  exports: [ RouterModule ]
})
export class AppRoutingModule {}
