import {Injectable} from '@angular/core';
import {HttpClient, HttpErrorResponse, HttpHeaders, HttpResponse} from '@angular/common/http';
import {environment} from '../environments/environment';
import {Observable, Subscription, throwError} from 'rxjs';
import {catchError} from 'rxjs/operators';
import { AuthService } from './auth.service';
import * as moment from 'moment';

@Injectable({
  providedIn: 'root'
})
export class ApiService {
    private apiUrl = environment.apiBaseUrl;

    constructor (private http: HttpClient, private authService: AuthService) {}

    getUser(token = this.authService.getToken()): Observable<any> {
        const httpOptions = {
            headers: new HttpHeaders({
                'Content-Type': 'application/json',
                'Authorization': token
            })
        };
        return this.http
            .get(this.apiUrl + 'db/user', httpOptions)
            .pipe(catchError(this.handleError));
    }

    login(username: string, password: string): Observable<any> {
        const httpOptions = {
            headers: new HttpHeaders({
                'Content-Type': 'application/json'
            })
        };
        return this.http
            .post(this.apiUrl + 'db/login', JSON.stringify({username, password}), httpOptions)
            .pipe(catchError(this.handleError));
    }

    createOrder(order): Observable<any> {
        const httpOptions = {
            headers: new HttpHeaders({
                'Content-Type': 'application/json',
                'Authorization': this.authService.getToken()
            })
        };
        return this.http
            .post(this.apiUrl + 'propago/order', JSON.stringify(order), httpOptions)
            .pipe(catchError(this.handleError));
    }

    getPart(sku): Observable<any> {
        const httpOptions = {
            headers: new HttpHeaders({
                'Content-Type': 'application/json',
                'Authorization': this.authService.getToken()
            })
        };
        return this.http
            .get(this.apiUrl + 'propago/part/' + sku, httpOptions)
            .pipe(catchError(this.handleError));
    }

    getPartInventory(sku): Observable<any> {
        const httpOptions = {
            headers: new HttpHeaders({
                'Content-Type': 'application/json',
                'Authorization': this.authService.getToken()
            })
        };
        return this.http
            .get(this.apiUrl + 'propago/part/inventory/' + sku, httpOptions)
            .pipe(catchError(this.handleError));
    }

    getPartPriceBreaks(sku): Observable<any> {
        const httpOptions = {
            headers: new HttpHeaders({
                'Content-Type': 'application/json',
                'Authorization': this.authService.getToken()
            })
        };
        return this.http
            .get(this.apiUrl + `propago/part/${sku}/pricebreaks`, httpOptions)
            .pipe(catchError(this.handleError));
    }

    searchOrders(startDate: moment.Moment, endDate: moment.Moment): Observable<any> {
        const httpOptions = {
            headers: new HttpHeaders({
                'Content-Type': 'application/json',
                'Authorization': this.authService.getToken()
            })
        };
        return this.http
            .get(this.apiUrl + `propago/orders/search?startdate=${startDate.format('YYYY-MM-DDTHH:mm:ss') + 'Z'}&enddate=${endDate.format('YYYY-MM-DDTHH:mm:ss') + 'Z'}`, httpOptions)
            .pipe(catchError(this.handleError));
    }

    createOrUpdateCart(cart): Observable<any> {
        const httpOptions = {
            headers: new HttpHeaders({
                'Content-Type': 'application/json',
                'Authorization': this.authService.getToken()
            })
        };
        return this.http
            .post(this.apiUrl + 'db/cart', JSON.stringify(cart), httpOptions)
            .pipe(catchError(this.handleError));
    }

    getCart(): Observable<any> {
        const httpOptions = {
            headers: new HttpHeaders({
                'Content-Type': 'application/json',
                'Authorization': this.authService.getToken()
            })
        };
        return this.http
            .get(this.apiUrl + 'db/cart', httpOptions)
            .pipe(catchError(this.handleError));
    }

    deleteCart(id: string): Observable<any> {
        const httpOptions = {
            headers: new HttpHeaders({
                'Content-Type': 'application/json',
                'Authorization': this.authService.getToken()
            })
        };
        return this.http
            .delete(`${this.apiUrl}db/cart/${id}`, httpOptions)
            .pipe(catchError(this.handleError));
    }

    createPendingOrder(order: any): Observable<any> {
        const httpOptions = {
            headers: new HttpHeaders({
                'Content-Type': 'application/json',
                'Authorization': this.authService.getToken()
            })
        };
        return this.http
            .post(this.apiUrl + 'db/orders', JSON.stringify(order), httpOptions)
            .pipe(catchError(this.handleError));
    }

    getPendingOrders(): Observable<any> {
        const httpOptions = {
            headers: new HttpHeaders({
                'Content-Type': 'application/json',
                'Authorization': this.authService.getToken()
            })
        };
        return this.http
            .get(this.apiUrl + 'db/orders?status=pending', httpOptions)
            .pipe(catchError(this.handleError));
    }

    getOrder(id: string): Observable<any> {
        const httpOptions = {
            headers: new HttpHeaders({
                'Content-Type': 'application/json',
                'Authorization': this.authService.getToken()
            })
        };
        return this.http
            .get(`${this.apiUrl}db/orders/${id}`, httpOptions)
            .pipe(catchError(this.handleError));
    }

    private handleError = (error: HttpErrorResponse) => {
        if (error.status === 401) {
            this.authService.setToken(null);
        }
        return throwError(error);
    }
} 
