import {Component, OnDestroy, OnInit} from '@angular/core';
import {Four51Service} from '../four51.service';
import {ActivatedRoute, ParamMap, Router} from '@angular/router';
import {Subscription} from 'rxjs';
import {navBarData} from '../../assets/ts/nav-bar-data';
import {MailerService, MailerMessage} from '../mailer.service';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {ProductModalComponent} from '../product-modal.component';
import {saveAs} from 'file-saver';
import {HttpClient} from '@angular/common/http';
import {MdvipUser} from '../models/mdvip-user';
import { ApiService } from '../api.service';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})

export class HomeComponent implements OnInit, OnDestroy {

  public navBarData;
  public selectedCategory: any;
  public headingText = 'Events & Marketing Materials';
  private subscriptions = new Subscription();
  private mdvipUser: MdvipUser;
  private role;

  constructor(private four51Service: Four51Service, private router: Router,
              private mailerService: MailerService, private route: ActivatedRoute,
              private modalService: NgbModal, private http: HttpClient, private apiService: ApiService) {
  }

  ngOnInit() {
    /*this.navBarData = navBarData.filter(
      x => (x.roles.includes('regular') &&
      x.roles.includes('plus') &&
      x.roles.includes('womens-health')));*/
    this.navBarData = [];

    const profileData = {};
    const membershipTier = profileData['membershipTier'];
    switch (membershipTier) {
      case 'AWP Plus': {
        this.role = 'plus';
        break;
      }
      case 'Women\'s Health': {
        this.role = 'womens-health';
        break;
      }
      default: {
        this.role = 'regular';
        break;
      }
    }
    const amgUsers = [7119, 6964, 7195, 6128, 1097, 8077]; 
    const physicianId = +profileData['physicianId'];
    if (amgUsers.includes(physicianId)) {
      this.role = 'ascension-medical-group';
      this.headingText = 'Marketing Materials';
    }
    this.navBarData = this.filterByRole(navBarData, this.role);
    /* Peter Palacio, MD (6282) cannot see */
    if (physicianId === 6282) {
      const womensHealth = this.navBarData.find(x => x.id === 10);
      womensHealth.items = womensHealth.items.filter(x => x.info !== 'MD64612');
      console.log(womensHealth);
    }

    this.subscriptions.add(
      this.route.paramMap.subscribe((params: ParamMap) => {
        const categoryid = +params.get('categoryid');
        this.selectedCategory = this.findCategory(this.navBarData, categoryid);
        for (const category of this.navBarData) {
          if (category.subcategories.includes(this.selectedCategory)) {
            if (category.isCollapsed) {
              category.isCollapsed = false;
            }
            break;
          }
        }
      })
    );
  }

  ngOnDestroy() {
    this.subscriptions.unsubscribe();
  }

  filterByRole(arr: any[], role: string): any[] {
    arr = arr.filter(x => x.roles.includes(role));
    for (const obj of arr) {
      if (obj.subcategories) {
        obj.subcategories = this.filterByRole(obj.subcategories, role);
      }
      if (obj.items) {
        obj.items = this.filterByRole(obj.items, role);
      }
    }
    return arr;
  }

  findCategory(arr, id): any {
    for (const object of arr) {
      if (object.id === id) {
        return object;
      } else {
        if (object.subcategories.length > 0) {
          const sub = this.findCategory(object.subcategories, id);
          if (sub) {
            return sub;
          }
        }
      }
    }
    return null;
  }

  async onItemSelected(e, {productID, sku}) {
    const [part, partInventory, partPriceBreaks]: [any, any, any] = await Promise.all([
      this.apiService.getPart(sku).toPromise(),
      this.apiService.getPartInventory(sku).toPromise(),
      this.apiService.getPartPriceBreaks(sku).toPromise()
    ]);
    const propago = {
      part: part.results,
      partInventory: partInventory.results,
      partPriceBreaks: partPriceBreaks.results
    }
    // console.log(part);
    // console.log(partInventory);
    // console.log(partPriceBreaks);
    if (!(part.results && partInventory.results && partPriceBreaks.results)) {
      console.log('Error loading part data');
      return;
    }
    const product: any = await this.four51Service.getProduct(productID).toPromise();
    let options: any;
    if (product.SpecCount > 0 && product.Type !== 'Static') {
      // variable
      options = { size: 'xl', scrollable: true,  windowClass : 'modal-proof', backdrop: 'static' };
    } else {
      options = { size: 'lg', backdrop: 'static', scrollable: true };
    }
    const modalRef = this.modalService.open(ProductModalComponent, options);
    modalRef.componentInstance.product = product;
    modalRef.componentInstance.propago = propago;
  }

  async getDescription(productId) {
    /*const product: any = await this.four51Service.getProduct(productId).toPromise();
    return product.Name;*/
  }

  async downloadFile(name: string, type?: string) {
    if (!type) {
      const nameParts = name.split('.');
      name = nameParts[0];
      type = nameParts[1];
    }
    const url = './assets/' + type + '/' + name + '.' + type;
    const data = await this.http.get(url, {responseType: 'blob'}).toPromise();
    saveAs(new Blob([data], {type: 'application/' + type}), name + '.' + type);
  }

  async testEmail() {
    const msg: MailerMessage = new MailerMessage();
    msg.To = ['tom@artoftechnology.com'];
    msg.From = 'Marketing Support Site <MarketingSupportSite@mdvip.com>';
    msg.Subject = 'Email Attachment Test';
    msg.Body = 'This message requires an email client that supports HTML email.';
    msg.Html = `
      <html>
          <head>
              <title>Email Image Test</title>
          </head>
          <body>
              <p>Please provide feedback on the the embedded image below.</p>` +
      '<img width=125 height=31 src="cid:mdvip.logo" alt="MDVIP Logo" style="width:125px; height=31px" />' +
      `</body>
      </html>
      `;
    const base64data = await this
      .getBase64data('https://mdvipsfauthsup.artoftechnology.com/ui/variableimage/PDFO/li5H7GkkyR0c2ZR3u2CGsZEA-e-e.pdf');
    const attachments = [];
    const attachment = {
      base64data: base64data,
      contentType: 'application/pdf',
      filename: 'invitation.pdf'
    };
    // this.mailerService.sendMessageWithLogo(msg);
    this.mailerService.sendMessageWithLogoAndAttachments(msg, attachments);
  }

  async getBase64data(url) {
    const blob = await this.http.get(url, {responseType: 'blob'}).toPromise();
    const fileReader = new FileReader();
    return new Promise((resolve, reject) => {
      fileReader.onerror = () => {
        fileReader.abort();
        reject(new DOMException('Problem parsing input file.'));
      };
      fileReader.onload = () => {
        resolve(fileReader.result);
      };
      fileReader.readAsDataURL(blob);
    });
  }
}
