import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {EventInfo, eventInfoWithVariant} from '../../models/event-info';
import * as moment from 'moment';
import {MdvipUserCookie} from '../../models/mdvip-user-cookie';
import {NgbDate} from '@ng-bootstrap/ng-bootstrap';
import {EventWaiverService} from './event-waiver.service';

@Component({
  selector: 'app-event-waiver',
  template: `
    <div class="proof-wrapper" *ngIf="_variant">
        <img [src]="imageUrl" (load)="onPreviewLoaded()" (error)="onPreviewError($event)" alt="Event Waiver Preview Image" />
        <div class="activity d-flex" *ngIf="isWaiting">
            <div class="spinner-border text-light m-auto" role="status" >
                <span class="sr-only">Loading...</span>
            </div>
        </div>
    </div>
  `,
  styles: [`
    .proof-wrapper {
        height: 100%;
        overflow-y: scroll;
    }
    .activity {
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        background-color: rgba(0,0,0,0.4);
        z-index: 99;
    }
  `]
})

export class EventWaiverComponent implements OnInit {
  @Input() eventInfo: EventInfo;
  public _variant: any;
  @Input()
  set variant(variant: any) {
    this.isWaiting = true;
    this._variant = variant;
    this.eventInfoFromVariant();
    this.refreshProofs();
  }
  @Output() eventInfoChange = new EventEmitter();
  @Output() hiddenFieldsChange = new EventEmitter();
  @Output() variantChange = new EventEmitter();

  private imageUrl;
  private proofUrl;
  private isWaiting: boolean;
  private mdvipUserCookie: MdvipUserCookie;
  private hiddenFields: string[];

  constructor (private eventWaiverService: EventWaiverService) {}

  ngOnInit(): void {
    this.hiddenFields = ['LastName', 'Specialty', 'Address', 'City', 'State', 'Zip', 'Phone', 'fax', 'email',
      'Website', 'staffEmail', 'facebook', 'twitter', 'speaker', 'speakervs', 'eventDate', 'venue_name', 'venue_address',
      'venue_city', 'venue_state', 'venue_zip', 'rsvp_phone', 'rsvp_message', 'patient_guests',
      'rsvpOnline', 'roomCapacity', 'topic', 'title', 'intro',  'brief_description', 'header_image', 'long_description' ];
    this.hiddenFieldsChange.emit(this.hiddenFields);
  }

  onPreviewLoaded() {
    this.isWaiting = false;
  }

  onPreviewError(e) {
    console.log(e);
    this.isWaiting = false;
  }

  eventInfoFromVariant() {
    this.eventInfo = eventInfoWithVariant(this._variant);
    this.eventInfoChange.emit(this.eventInfo);
  }

  async refresh() {
    this.isWaiting = true;
    this._variant = await this.eventWaiverService.refresh(this._variant, this.eventInfo);
    this.variantChange.emit(this._variant);
    this.refreshProofs();
  }

  ngbDateToString(object: NgbDate) {
    return (object) ? object.year + '-' + object.month + '-' + object.day : '';
  }

  refreshProofs() {
    this.imageUrl = this._variant.PreviewUrl + '?r=' + Math.random();
    this.proofUrl = this._variant.ProofUrl + '&r=' + Math.random();
  }
}
