import {Component, Input, OnDestroy, OnInit} from '@angular/core';
import {NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';
import {Four51Service} from '../four51.service';
import {CartService} from '../cart.service';
import {Subscription} from 'rxjs';
import {OrderLimitResponse, OrderLimitService} from '../order-limit.service';
import {Router} from '@angular/router';

@Component({
  selector: 'order-detail-modal-component',
  template: `
      <div class="modal-header">
          <h4 class="modal-title">Order {{(order) ? order.ExternalID : ''}}</h4>
          <button type="button" class="close" aria-label="Close" (click)="activeModal.dismiss()">
              <span aria-hidden="true">&times;</span>
          </button>
      </div>
      <div class="modal-body">
          <ng-container *ngIf="order">
            <h5>Status</h5>
            <div class="mb-3">{{order.StatusText}}</div>
            <table class="table">
                <thead>
                <tr class="bg-primary text-light">
                    <th scope="col">Description</th>
                    <th scope="col">Proof</th>
                    <th scope="col">Quantity</th>
                    <th></th>
                </tr>
                </thead>
                <tbody>
                <tr *ngFor="let lineItem of order.LineItems; index as i " >
                    <td>{{lineItem.Product.Name}}</td>
                    <td>
                        <a href="javascript:void(0)" *ngIf="lineItem.Product.Type === 'VariableText'
                            && lineItem.Variant.InteropID"
                           (click)="downloadProof(lineItem)"><i class="fa fa-download"></i></a>
                        <span *ngIf="!(lineItem.Product.Type === 'VariableText'
                            && lineItem.Variant.InteropID)">N/A</span>
                    </td>
                    <td>{{lineItem.Quantity}}</td>
                    <td><button class="btn btn-primary btn-sm" type="button"
                           (click)="reorderLineItem(lineItem)"
                        [disabled]="lineItem.Product.Type === 'VariableText' && !lineItem.Variant.InteropID" >
                        <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"
                              *ngIf="lineItem === lineItemForReorder"></span>
                        reorder</button></td>
                </tr>
                </tbody>
                <tfoot>
                </tfoot>
            </table>
              <h5>Shipping Address</h5>
              <div *ngIf="shipAddress" class="mb-3">
                  {{shipAddress.FirstName}} {{shipAddress.LastName}}<br />
                  <span *ngIf="shipAddress.CompanyName">{{shipAddress.CompanyName}}<br /></span>
                  {{shipAddress.Street1}}<br />
                  <span *ngIf="shipAddress.Street2">{{shipAddress.Street2}}<br /></span>
                  {{shipAddress.City}}, {{shipAddress.State}} {{shipAddress.Zip}}
              </div>
              <h5>Tracking Information</h5>
              <div class="list-group list-group-flush">
                <div *ngIf="!shipments || shipments.length === 0">
                    N/A
                </div>
                <div class="list-group-item" *ngFor="let shipment of shipments">
                    <div><span class="font-weight-bold">Shipper: </span>{{shipment.Shipper}}</div>
                    <div><span class="font-weight-bold">Tracking number: </span>
                        <a [href]="shipment.TrackingURL" target="_blank">{{shipment.TrackingNumber}}</a></div>
                    <div><span class="font-weight-bold">Items:</span>
                      <div *ngFor="let item of shipment.Items">{{item.ProductID}} - {{item.QuantityShipped}}</div>
                    </div>
                </div>
              </div>
          </ng-container>
          <div *ngIf="!order" class="activity d-flex">
              <div class="spinner-border text-dark m-auto" role="status" >
                  <span class="sr-only">Loading...</span>
              </div>
          </div>
      </div>
      <div class="modal-footer">
          <div *ngIf="message.text.length > 0" class="alert my-0" [ngClass]="message.class">
              {{message.text}}
          </div>
          <button *ngIf="showCartButton" type="button" class="btn btn-primary ml-3" (click)="closeAndNavigateToCart()">Cart</button>
          <button class="btn btn-primary ml-3" type="button" (click)="activeModal.dismiss()" >Done</button>
      </div>
  `,
  styleUrls: ['../modal.component.scss'],
  styles: ['.list-group-item {padding-left: 0;}']
})
export class OrderDetailModalComponent implements OnInit, OnDestroy {
  public order: any;
  private shipAddress: any;
  public message = {text: '', class: ''};
  private subscription: Subscription;
  private cartCount;
  private lineItemForReorder: any = null;
  public showCartButton = false;
  public shipments: any;
  @Input() orderId: string;
  constructor(public activeModal: NgbActiveModal, private four51Service: Four51Service, private cartService: CartService,
              private orderLimitService: OrderLimitService, private router: Router) {}

  async ngOnInit() {
    this.subscription = new Subscription();
    const cartSubscription = this.cartService.cartObservable().subscribe(cart => {
      if (cart) {
        if (this.lineItemForReorder && cart.placeOrderLines.length === this.cartCount + 1) {
          this.message.text = 'Successfully added ' + this.lineItemForReorder.Product.Name + ' to your cart.';
          this.lineItemForReorder = null;
          this.message.class = 'alert-success';
          this.showCartButton = true;
        }
        this.cartCount = cart.placeOrderLines.length;
      } else {
        this.cartCount = 0;
      }
    });
    this.subscription.add(cartSubscription);
    // this.order  = await this.four51Service.getOrderById(this.orderId).toPromise();
    // this.shipAddress = await this.four51Service.getAddresses(this.order.ShipAddressID).toPromise();
    // console.log(this.order);
    // this.shipments = await this.four51Service.getOrderShipments(this.orderId).toPromise();
    // console.log(this.shipments);
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  downloadProof(lineItem: any) {
    const w: any = window;
    const isIOS = (/iPad|iPhone|iPod/.test(navigator.platform) ||
      (navigator.platform === 'MacIntel' && navigator.maxTouchPoints > 1)) &&
      !w.MSStream;
    const url = lineItem.ProofUrl;
    if (isIOS) {
      window.open(url, '_blank');
    } else {
      window.location.replace(url);
    }
    this.message.text = 'Your file is downloading.';
    this.message.class = 'alert-success';
    this.showCartButton = false;
  }

  async reorderLineItem(lineItem: any) {
    this.lineItemForReorder = lineItem;
    console.log(lineItem);
    const resp: OrderLimitResponse = await this.orderLimitService.getOrderLimit({
      sku: lineItem.sku,
      quantity: lineItem.quantity
    });
    if (resp.canOrder) {
      let variantID;
      if (lineItem.Product.Type === 'VariableText' && lineItem.Variant.InteropID) {
        variantID = lineItem.Variant.InteropID;
      }
      // this.cartService.addToCart(lineItem.Product.InteropID, lineItem.Quantity, variantID);
    } else {
      this.lineItemForReorder = null;
      this.message.text = resp.message;
      this.message.class = 'alert-danger';
      this.showCartButton = false;
    }
  }

  closeAndNavigateToCart() {
    this.activeModal.dismiss();
    this.router.navigate(['/cart']);
  }
}
