import {Component, OnDestroy, OnInit} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import { ApiService } from '../api.service';
import { AuthService } from '../auth.service';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})

export class LoginComponent implements OnInit {

  public username;
  public password;
  public isLoading = true;

  constructor(
    private route: ActivatedRoute,
    protected router: Router,
    private apiService: ApiService,
    private authService: AuthService
  ) {}

  async ngOnInit() {
    console.log('LoginComponent initialized');
    if (this.route.snapshot.queryParams.token) {
      const token = this.route.snapshot.queryParams.token;
      const response = await this.apiService.getUser(token).toPromise();
      if (response) {
        this.authService.setToken(token);
      }
    } else {
      this.isLoading = false;
    }
  }

  async login() {
    // console.log('Logging in with username:', this.username, 'and password:', this.password);
    const response = await this.apiService.login(this.username, this.password).toPromise();
    if (response) {
      this.authService.setToken(response.token);
    }
  }

}
