import {
  Component, ElementRef,
  Input, OnInit, ViewChild
} from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import {Four51Service} from './four51.service';
import {CartService} from './cart.service';
import {Subscription} from 'rxjs';
import {Router} from '@angular/router';
import {OrderLimitResponse, OrderLimitService} from './order-limit.service';

@Component({
  selector: 'app-pdf-modal-component',
  template: `
      <div class="modal-header">
          <h4 class="modal-title">{{propago.part.partName}}</h4>
          <div class="ml-auto">
              <button *ngIf="shouldShowDownload" class="btn btn-primary btn-sm" (click)="download()"
                      [disabled]="!isProofed || isUpdating || isWaiting">
                  <i class="fa fa-download"></i> PDF
              </button>
              <div *ngIf="shouldShowFit" class="btn-group ml-2" role="group" aria-label="Proof Zoom">
                  <button type="button" class="btn btn-outline-primary btn-sm" [class.active]="shouldFit"
                          (click)="shouldFit = true">Fit in Window</button>
                  <button type="button" class="btn btn-outline-primary btn-sm" [class.active]="!shouldFit"
                          (click)="shouldFit = false">Max Size</button>
              </div>
          </div>
          <button type="button" ngbAutofocus class="close ml-0" aria-label="Close" (click)="activeModal.dismiss()">
              <span aria-hidden="true">&times;</span>
          </button>
      </div>
      <div class="modal-body modal-body-proof d-flex no-gutters" *ngIf="product.SpecCount > 0 && product.Type !== 'Static'">
          <div class="col-lg-4">
              <app-spec [specs]="(isEditing) ? variant.Specs : product.Specs" [isEditing]="isEditing"
                        (specsChange)="specsChange($event)"></app-spec>
          </div>
          <div class="col-lg-8">
              <div class="proof-wrapper" *ngIf="variant.InteropID">
                  <img [src]="imageUrl" [class.fit]="shouldFit" (load)="onPreviewLoaded($event)" (error)="onPreviewError($event)"
                       [alt]="product.Name + 'Preview Image'" />
                  <div class="activity d-flex" *ngIf="isWaiting">
                      <div class="spinner-border text-light m-auto" role="status" >
                          <span class="sr-only">Loading...</span>
                      </div>
                  </div>
              </div>
          </div>
      </div>
      <div class="modal-body" *ngIf="product.SpecCount === 0 || product.Type === 'Static'">

          <div class="text-center">
              <img class="mx-auto" [src]="propago.part.thumbnailFile" [alt]="propago.part.partName" />
          </div>


      </div>
      <div class="modal-footer">
          <form *ngIf="!shouldShowConfirmation && !errorMessage" class="form-inline" #addToCartForm="ngForm" name="addToCartForm"
                id="addToCartForm"
                (ngSubmit)="onAddToCartFormSubmit(addToCartForm)" novalidate>
              <div class="form-group" *ngIf="propago.partPriceBreaks.length > 0">
                  <label for="quantity">Quantity</label>
                  <select id="quantity" name="quantity" [(ngModel)]="quantity" class="form-control mx-3">
                      <option *ngFor="let priceBreak of propago.partPriceBreaks">{{priceBreak.quantity}}</option>
                  </select>
              </div>
              <div class="form-group" *ngIf="!propago.partPriceBreaks.length > 0">
                  <label for="quantity">Quantity</label>
                  <input type="text" name="quantity" #refQuantity="ngModel" [(ngModel)]="quantity" class="form-control mx-3"
                         required [min]="1" digits [class.is-invalid]="refQuantity.errors &&
                         (refQuantity.dirty || addToCartForm.submitted) "/>
              </div>
              <button type="submit" class="btn btn-primary" [disabled]="!isProofed || isUpdating || isWaiting">
                  <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true" *ngIf="isUpdating"></span>
                  Add to Cart
              </button>
          </form>
          <ng-container *ngIf="shouldShowConfirmation">
              <div class="alert alert-success my-0" >Successfully added {{quantity}} to your cart.</div>
              <button type="button" class="btn btn-outline-secondary mx-3" (click)="activeModal.dismiss()">Close</button>
              <button type="button" class="btn btn-primary" (click)="closeAndNavigateToCart()" >Go to Cart</button>
          </ng-container>
          <div *ngIf="errorMessage" class="alert alert-danger my-0" [innerHTML]="errorMessage"></div>
      </div>
  `,
  styleUrls: ['./modal.component.scss'],
  styles: [`
    .proof-wrapper {
      height: 100%;
      overflow-y: scroll;
    }
    .activity {
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      background-color: rgba(0,0,0,0.4);
      z-index: 99;
    }
    .fit {
        max-height: 100%;
        max-width: 100%;
    }
    `
  ]
})
export class ProductModalComponent implements OnInit {

  @Input() product: any;
  @Input() propago: any;
  private quantity: number;
  @Input() variant: any;
  private isProofed: boolean;
  private imageUrl;
  private proofUrl;
  private isWaiting: boolean;
  private subscription: Subscription;
  private isUpdating = false;
  private cartCount: number;
  public shouldShowConfirmation = false;
  public errorMessage: string;
  private isEditing: boolean;
  public shouldFit = false;
  public shouldShowFit = false;
  private downloadableProducts = ['82E756E2-5D8B-441B-B281-473853AFE714', 'E93CACCD-C644-4FE8-AF48-23AA8654E09A'];
  public shouldShowDownload = false;

  constructor(public activeModal: NgbActiveModal, private four51Service: Four51Service,
              private cartService: CartService, private router: Router, private orderLimitService: OrderLimitService) {}

  ngOnInit(): void {
    this.subscription = new Subscription();
    const cartSubscription = this.cartService.cartObservable().subscribe(cart => {
      if (cart) {
        if (this.isUpdating && cart.placeOrderLines.length === this.cartCount + 1) {
          this.isUpdating = false;
          this.shouldShowConfirmation = true;
        }
        this.cartCount = cart.placeOrderLines.length;
      } else {
        this.cartCount = 0;
      }
    });
    this.subscription.add(cartSubscription);
    if (this.propago.partPriceBreaks.length > 0) {
      this.quantity = this.propago.partPriceBreaks[0].quantity;
    }
    if (this.variant) {
      this.isEditing = true;
      this.isProofed = true;
    } else {
      this.isEditing = false;
      if (this.product.SpecCount > 0 && this.product.Type !== 'Static') {
        this.isProofed = false;
        this.variant = {
          ProductInteropID: this.product.InteropID,
          Specs: this.product.Specs
        };
      } else {
        this.isProofed = true;
      }
    }
    if (this.downloadableProducts.includes(this.product.InteropID)) {
      this.shouldShowDownload = true;
    }
  }

  onPreviewLoaded(e) {
    this.isWaiting = false;
    if (e.target.offsetWidth > 960) {
      this.shouldShowFit = true;
    } else {
      this.shouldShowFit = false;
    }
  }

  onPreviewError(e) {
    console.log(e);
    this.isWaiting = false;
    this.errorMessage = 'ATTENTION: We are unable to process this request due to an issue with the photo on file. ' +
      'Please email <a href=mailto:PhysicianPhotos@mdvip.com">PhysicianPhotos@mdvip.com</a> for assistance.';
  }

  specsChange(isFormValid: boolean) {
    this.isProofed = isFormValid;
    this.variant.Specs = this.product.Specs;
    this.updateVariant();
  }

  async updateVariant() {
    this.isWaiting = true;
    const response = await this.four51Service.createVariant(this.variant).toPromise();
    this.variant = response.body;
    this.imageUrl = this.variant.PreviewUrl + '?r=' + Math.random();
  }

  async onAddToCartFormSubmit(addToCartForm) {
    if (addToCartForm.valid) {
      this.isUpdating = true;
      const resp: OrderLimitResponse = await this.orderLimitService.getOrderLimit({
        sku: this.propago.part.displaySku, 
        quantity: this.quantity
      });
      if (resp.canOrder) {
        const variantID = (this.variant) ? this.variant.InteropID : undefined;
        const file = (this.variant) ? this.variant.ProofUrl.replace('/PDF/', '/PDFO/') : '';
        this.cartService.addToCart(this.product.InteropID, this.propago, this.quantity, file, variantID );
      } else {
        this.errorMessage = resp.message;
      }
    }
  }

  closeAndNavigateToCart() {
    this.activeModal.dismiss();
    this.router.navigate(['/cart']);
  }

  download() {
    const w: any = window;
    const isIOS = (/iPad|iPhone|iPod/.test(navigator.platform) ||
      (navigator.platform === 'MacIntel' && navigator.maxTouchPoints > 1)) &&
      !w.MSStream;
    const url = this.variant.ProofUrl;
    if (isIOS) {
      window.open(url, '_blank');
    } else {
      window.location.replace(url);
    }
  }

}
