import {Component, OnDestroy, OnInit} from '@angular/core';
import {CartService} from '../cart.service';
import {Subscription} from 'rxjs';
import {ActivatedRoute, ParamMap, Router} from '@angular/router';
import {Four51Service} from '../four51.service';
import {MailerMessage, MailerService} from '../mailer.service';
import {MailTemplateService, MailTemplateValue} from '../mail-template.service';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {ProductEditModalComponent} from '../product-edit-modal.component';
import {environment} from '../../environments/environment';
import {PhysicianEmailAddrService} from '../physician-email-addr.service';
import * as moment from 'moment-timezone';
import {OrderLimitResponse, OrderLimitService} from '../order-limit.service';
import { ApiService } from '../api.service';

@Component({
  selector: 'app-cart',
  templateUrl: './cart.component.html',
  styleUrls: ['./cart.component.scss']
})

export class CartComponent implements OnInit, OnDestroy {
  private subscription: Subscription;
  private cart: any;
  private isUpdating = false;
  public isCheckout = false;
  public isComplete = false;
  private user: any = null;
  private shipAddress;
  private isOrderWaiting = false;
  private orderNumber: string;
  public needsApproval = false;
  constructor(private cartService: CartService, private route: ActivatedRoute, private router: Router,
              private four51Service: Four51Service,
              private apiService: ApiService,
              private mailerService: MailerService,
              private mailTemplateService: MailTemplateService, private modalService: NgbModal,
              private physicianEmailAddrService: PhysicianEmailAddrService, private orderLimitService: OrderLimitService) {}

  async ngOnInit() {
    this.subscription = new Subscription();
    const cartSubscription = this.cartService.cartObservable().subscribe(cart => {
      if (this.isUpdating) {
        this.isUpdating = false;
      }
      this.cart = cart;
      if (this.cart && this.cart.approvals.length > 0) { // TODO: where does this come from?
        this.needsApproval = true;
      }
    });
    this.subscription.add(cartSubscription);
    const paramMapSubscription = this.route.paramMap.subscribe((params: ParamMap) => {
      const checkout = params.get('checkout');
      const complete = params.get('complete');
      if (checkout) {
        this.isCheckout = true;
      } else {
        this.isCheckout = false;
      }
      if (complete) {
        this.isComplete = true;
      } else {
        this.isComplete = false;
      }
    });
    this.subscription.add(paramMapSubscription);
    this.user = await this.apiService.getUser().toPromise();
  }
  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  async selectLineItem(orderLine: any) {
    const product: any = await this.four51Service.getProduct(orderLine.productId).toPromise();
    const variant: any = await this.four51Service.getVariant(orderLine.productId, orderLine.assetId).toPromise();
    const options: any = { size: 'xl', scrollable: true,  windowClass : 'modal-proof', backdrop: 'static' };
    const modalRef = this.modalService.open(ProductEditModalComponent, options);
    modalRef.componentInstance.product = product;
    modalRef.componentInstance.variant = variant;
    modalRef.result.then(result => {
      console.log('checking cart to be safe');
      this.cartService.checkCart();
    }).catch(() => {
      // nothing to do
    });
  }

  async removeLineItem(index: number) {
    this.cartService.removeFromCart(index);
  }

  async updateQuantities(cartForm) {
    if (cartForm.valid) {
      let shouldSave = true;
      this.isUpdating = true;
      // TODO: order limit service
      for (const orderLine of this.cart.placeOrderLines) {
        const resp: OrderLimitResponse = await this.orderLimitService.getOrderLimit({
          sku: orderLine.sku, 
          quantity: orderLine.quantity
        });
        if (!resp.canOrder) {
          // this.errorMessage = resp.message;
          shouldSave = false;
        }
      }
      if (shouldSave) {
        this.cartService.saveCart();
      } else {
        this.cartService.checkCart();
      }
    }
  }

  async startCheckout() {
    this.cartService.checkCart();
    if (this.user && this.user.physician) {
      this.shipAddress = {
        CompanyName: this.user.physician.fullName || '',
        Street1: this.user.physician.address1 || '',
        Street2: this.user.physician.address2 || '',
        City: this.user.physician.city || '',
        State: this.user.physician.state || '',
        Zip: this.user.physician.zip || '',
        Country: 'US',
        Phone: this.user.physician.phone || '',
        FirstName: this.user.physician ? 'Dr.' : '',
        LastName: this.user.physician.lastname || '',
      } 
    } else {
      this.shipAddress = {
        CompanyName: '',
        Street1: '',
        Street2: '',
        City: '',
        State: '',
        Zip: '',
        Country: 'US',
        Phone: '',
        FirstName: '',
        LastName: '',
      }
    }
    this.router.navigate(['/cart', {checkout: true}]);
  }

  async placeOrder(shipForm: any) {
    if (shipForm.valid) {
      this.isOrderWaiting = true;

      const placeOrderLines = this.cart.placeOrderLines.map((line: any) => {
        return {
          sku: line.sku,
          quantity: line.quantity,
          printFile: line.printFile,
          assetId: line.assetId
        }
      });

      const email = (this.user.physician) ? this.user.physician.email : 'marketingsupportsite@mdvip.com'

      let comments = '';
      if (!environment.production || environment.isTest) {
        comments = 'TEST-EVENT-SITE';
      } else if (this.needsApproval) {
        comments = email;
      }
      const propagoOrder = {
        signatureRequired: false,
        isExpedited: false,
        promoCode: '',
        invoiceEmail: '',
        mailInvoice: false,
        username: this.user.username,
        orderTag: '',
        comments,
        alternativeEmail: '',
        carrier: 'FedEx - Ground',
        desiredShipDate: moment().add(1,'d').startOf('d').format('YYYY-MM-DDThh:mm:ssZ'), //'2024-12-11T05:00:00Z',
        shippingAddress: {
          contact: `${this.shipAddress.FirstName} ${this.shipAddress.LastName}`,
          company: this.shipAddress.CompanyName,
          address1: this.shipAddress.Street1,
          address2: this.shipAddress.Street2,
          address3: '',
          city: this.shipAddress.City,
          state: this.shipAddress.State,
          country: 'US',
          zip: this.shipAddress.Zip,
          externalId: '',
          phone: this.shipAddress.Phone,
          email,
          isResidential: false,
          isSignatureRequired: false
        },
        accountingUnit: '',
        billingAddress: {
          company: 'MDVIP',
          contact: 'Accounts Payable',
          address1: '4950 Communication Ave.',
          address2: 'Suite 100',
          city: 'Boca Raton',
          state: 'FL',
          country: 'US',
          zip: '33431',
          externalId: ''
        },
        placeOrderLines,
        orderField1: '',
        orderField2: '',
        orderField3: '',
        orderField4: '',
        orderField5: this.user.username,
        poNumber: '',
        verifyPONumber: false,
        thirdPartyAccountNumber: '',
        doNotSendNotificationEmails: null,
      };

      const response = await this.apiService.createOrder(propagoOrder).toPromise();
      if (response && !response.error && response.results) {
        const itemsForEmail = this.cart.placeOrderLines.map((line: any) => {
          return `${line.quantity} ${line.name}`;
        });
        await this.apiService.deleteCart(this.cart.id).toPromise();
        this.cartService.checkCart();
        this.orderNumber = response.results;
        this.isOrderWaiting = false;
        this.router.navigate(['/cart', {complete: true}]);

        // send email
        try {
          if (this.needsApproval) {
            /*
             * Order Submitted for Approval Email
             */
            const collateralSubmittedInternalEmail: MailerMessage = new MailerMessage();
            collateralSubmittedInternalEmail.Subject = `${this.user && this.user.physician ? this.user.physician.fullName : ''}, Order '${this.orderNumber} has been received`;
            collateralSubmittedInternalEmail.Bcc = environment.emailBcc;
            if (!environment.production || environment.isTest) {
              collateralSubmittedInternalEmail.To = environment.emailTo;
              collateralSubmittedInternalEmail.Subject = 'TESTING: ' + collateralSubmittedInternalEmail.Subject;
            } else {
              collateralSubmittedInternalEmail.To = ['Marketing Support Site <MarketingSupportSite@mdvip.com>'];
              collateralSubmittedInternalEmail.To.push('pdmadmin@mdvip.com');
            }
            collateralSubmittedInternalEmail.From = 'Marketing Support Site <MarketingSupportSite@mdvip.com>';
            collateralSubmittedInternalEmail.Body = 'This message requires an email client that supports HTML email.';
            const approval_url = 'https://southeasternprinting.four51ordercloud.com/mdvip/order';
            let items = '';
            // const itemOpen = '<p>';
            // const itemClose = '</p>';
            const itemOpen = '<tr><td valign="top" ' +
              'style="width:0.1%; padding: 3px 18px 0 0; font-size: 32px; mso-line-height-rule:exactly; mso-text-raise: -8px; ' +
              'line-height: 16px; font-family: Helvetica, Arial, sans-serif; color: #666666;">&bull;</td><td valign="top" ' +
              'style="font-size: 16px; line-height: 25px; font-family: Helvetica, Arial, sans-serif; color: #666666;">';
            const itemClose = '</td></tr>';
            for (const item of itemsForEmail) {
              items += itemOpen + item + itemClose;
            }
            const collateralSubmittedInternalValues: MailTemplateValue[] = [
              {name: 'approval_url', value: approval_url},
              {name: 'date_submitted', value: moment().format('MM/DD/YYYY h:mm A')},
              {name: 'fullname', value: this.user && this.user.physician ? this.user.physician.fullName : ''},
              {name: 'physician_id', value: this.user && this.user.physician ? this.user.physician.physicianId : ''},
              {name: 'items', value: items}
            ];
            collateralSubmittedInternalEmail.Html = await this.mailTemplateService
              .replaceValues('./assets/html/collateralSubmittedInternal.html', collateralSubmittedInternalValues);
            this.mailerService.sendMessage(collateralSubmittedInternalEmail);
  
          } else {
            /*
             * Order Placed Doctor Email
             */
            const orderPlacedDoctorEmail: MailerMessage = new MailerMessage();
            orderPlacedDoctorEmail.Subject = `${this.user && this.user.physician ? this.user.physician.fullName : ''}, Order ${this.orderNumber} has been received`;
            orderPlacedDoctorEmail.Bcc = environment.emailBcc;
            if (!environment.production || environment.isTest) {
              orderPlacedDoctorEmail.To = environment.emailTo;
              orderPlacedDoctorEmail.Subject = 'TESTING: ' + orderPlacedDoctorEmail.Subject;
            } else {
              this.physicianEmailAddrService.setAddresses(
                orderPlacedDoctorEmail,
                this.user && this.user.physician ? this.user.physician.email : '',
                this.user && this.user.physician ? this.user.physician.staffEmail : '',
                this.user && this.user.physician ? this.user.physician.ptmEmail : '',
                this.user && this.user.physician ? this.user.physician.pdmEmail : '',
                this.user && this.user.physician ? this.user.physician.practiceStatus : '',
                this.user && this.user.physician ? this.user.physician.relationshipStatus : '',
                this.user && this.user.physician ? this.user.physician.physicianStatusType : '');
            }
            orderPlacedDoctorEmail.From = 'Marketing Support Site <MarketingSupportSite@mdvip.com>';
            orderPlacedDoctorEmail.Body = 'This message requires an email client that supports HTML email.';
            let items = '';
            const itemOpen = '<tr><td valign="middle" ' +
              'style="width:0.1%; padding: 0 18px 0 0; font-size: 32px; mso-line-height-rule:exactly; mso-text-raise: -8px; ' +
              'line-height: 16px; font-family: Helvetica, Arial, sans-serif; color: #666666;">&bull;</td><td valign="top" ' +
              'style="font-size: 16px; line-height: 25px; font-family: Helvetica, Arial, sans-serif; color: #666666;">';
            const itemClose = '</td></tr>';
            for (const item of itemsForEmail) {
              items += itemOpen + item + itemClose;
            }
            // const historyUrl = window.location.origin + '/history';
            const historyUrl = environment.mdvipConnectUrl;
            const mailTemplateValues: MailTemplateValue[] = [
              {name: 'order_number', value: this.orderNumber},
              {name: 'items', value: items},
              {name: 'history_url', value: historyUrl}
            ];
            orderPlacedDoctorEmail.Html = await this.mailTemplateService
              .replaceValues('./assets/html/orderPlacedDoctor.html', mailTemplateValues);
            this.mailerService.sendMessageWithLogo(orderPlacedDoctorEmail);
          }
        } catch (error) {
          let body = 'Order: ' + this.orderNumber + '\n' + error.message;
          let htmlBody = '<div>Order: ' + this.orderNumber + '</div></div><div>' + error.message + '</div>';
          if (this.needsApproval) {
            body += '\n' + 'Approval Needed';
            htmlBody += '<div>Approval Needed</div>';
          } else {
            body += '\n' + 'No Approval Needed';
            htmlBody += '<div>No Approval Needed</div>';
          }
          const errorEmail: MailerMessage = new MailerMessage();
          errorEmail.To = ['tom@artoftechnology.com', 'suzanne@seprint.com'];
          errorEmail.From = 'MarketingSupportSite@mdvip.com';
          errorEmail.Subject = 'Event Site Cart Email Error';
          errorEmail.Body = body;
          errorEmail.Html = htmlBody;
          this.mailerService.sendMessage(errorEmail);
        }

      } else {
        console.log(response);
        this.isOrderWaiting = false;
      }
    }
  }
}


