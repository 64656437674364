import {Component, OnInit} from '@angular/core';
import {MdvipUser} from './models/mdvip-user';
import {Router} from '@angular/router';
import {environment} from '../environments/environment';

@Component({
  selector: 'app-root',
  template: '<router-outlet></router-outlet>',
  styles: []
})
export class AppComponent implements OnInit {
  firstname = '';
  lastname = '';

  public mdvipUser: MdvipUser;
  private logoUrl = '';
  private isApprover = false;
  private cartLines = 0;
  // private mdvipUserCookie: MdvipUserCookie;
  constructor(private router: Router) {}
  ngOnInit() {

  }

  brandClicked(e) {
    if (this.mdvipUser) {
      this.router.navigate(['/home']);
    } else {
      e.preventDefault();
      window.location.replace(environment.storefrontUrl);
    }
  }


  logout(e) {
    e.preventDefault();
    console.log('Logging out');
  }
}
