import {Component, OnDestroy, OnInit} from '@angular/core';
import {navBarData} from '../../assets/ts/nav-bar-data';
import {Subscription} from 'rxjs';
import {MdvipUser} from '../models/mdvip-user';
import {ActivatedRoute, ParamMap, Router} from '@angular/router';

@Component({
  selector: 'app-menu',
  template: `
    <ul class="nav flex-column">
      <li class="nav-item">
        <a class="nav-link" [routerLink]="['/home']" routerLinkActive="active"
           [routerLinkActiveOptions]="{exact: true}">Home</a>
      </li>
      <li class="nav-item" *ngFor="let category of navBarData">
        <a class="nav-link" (click)="checkToggle($event, category)"
           [routerLink]="getLink(category)"
           [class.active]="isActive(category)">
          {{category.name}}
        </a>
        <ul class="nav flex-column" *ngIf="category.subcategories" [ngbCollapse]="category.isCollapsed">
          <li class="nav-item" *ngFor="let subcategory of category.subcategories">
            <a class="nav-link"
               [routerLink]="getLink(subcategory)"
               [class.active]="isActive(subcategory)" >
              {{subcategory.name}}
            </a>
            <!-- [class.active]="isActive(getLink(subcategory))" -->
          </li>
        </ul>
      </li>
    </ul>
  `,
  styleUrls: ['./home.component.scss']
})

export class MenuComponent implements OnInit {

  private subscriptions = new Subscription();
  private mdvipUser: MdvipUser;
  private role: any;
  public navBarData: any;
  constructor(private activatedRoute: ActivatedRoute, private router: Router) {}
  ngOnInit() {
    const profileData = {};
    const membershipTier = profileData['membershipTier'];
    switch (membershipTier) {
      case 'AWP Plus': {
        this.role = 'plus';
        break;
      }
      case 'Women\'s Health': {
        this.role = 'womens-health';
        break;
      }
      default: {
        this.role = 'regular';
        break;
      }
    }
    const amgUsers = [7119, 6964, 7195, 6128, 1097];
    const physicianId = +profileData['physicianId'];
    if (amgUsers.includes(physicianId)) {
      this.role = 'ascension-medical-group';
    }
    this.navBarData = this.filterByRole(navBarData, this.role);
    // TODO more universal way to do this
    if (this.activatedRoute.snapshot.url.map(x => x.path).includes('events')) {
      this.navBarData.find(x => x.id === 1).isCollapsed = false;
    }
  }

  filterByRole(arr: any[], role: string): any[] {
    arr = arr.filter(x => x.roles.includes(role));
    for (const obj of arr) {
      if (obj.subcategories) {
        obj.subcategories = this.filterByRole(obj.subcategories, role);
      }
      if (obj.items) {
        obj.items = this.filterByRole(obj.items, role);
      }
    }
    return arr;
  }

  getLink(category) {
    if (!category.link) {
      if (this.activatedRoute.snapshot.url[0].path !== 'home' && category.subcategories.length > 0) {
        return [];
      } else {
        return ['/home', category.id];
      }
    }
    return category.link;
  }

  isActive(category) {
    let link = this.getLink(category);
    if (Array.isArray(link) && link.length > 0) {
      link = this.router.createUrlTree(link).toString();
    }
    if (this.router.url === link) {
      return true;
    }
    return false;
  }

  checkToggle(e, category) {
    if (category['isCollapsed'] !== undefined) {
      if (category.isCollapsed) {
        category.isCollapsed = false;
      } else {
        // if (e.target.classList.contains('active'))
        if (this.activatedRoute.snapshot.url[0].path !== 'home' || this.isActive(category)) {
          category.isCollapsed = true;
        }
      }
    }
  }
}
