import {ChangeDetectorRef, Component, ElementRef, OnDestroy, OnInit, ViewChild, ViewChildren} from '@angular/core';
import {HttpClient, HttpResponse} from '@angular/common/http';
import {MdvipUser} from '../models/mdvip-user';
import {CookieService} from 'ngx-cookie-service';
import {Four51Service} from '../four51.service';
// import xml2js from 'xml2js';
import Quill from 'quill';
import {NgbAccordion, NgbDate, NgbDateParserFormatter, NgbModal, NgbPopover} from '@ng-bootstrap/ng-bootstrap';
// import * as ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import { NgbDateCustomParserFormatter} from '../dateformat';
import * as moment from 'moment-timezone';
import { MaskPipe } from 'ngx-mask';
import {DomSanitizer, SafeUrl} from '@angular/platform-browser';
import {states} from '../../assets/ts/states';
import {cobrands} from '../../assets/ts/cobrand';
import {headerImages, HeaderImage} from '../../assets/ts/header-images';
import {ifTrue} from 'codelyzer/util/function';
import {saveAs} from 'file-saver';
import {ActivatedRoute, NavigationEnd, ParamMap, Router} from '@angular/router';
import {MailerMessage, MailerService} from '../mailer.service';
import {Subscription} from 'rxjs';
import set = Reflect.set;
import {switchMap} from 'rxjs/operators';
import {Location} from '@angular/common';
import {Guid} from 'guid-typescript';
import {EventInfo, VenueType} from '../models/event-info';

import {PdfModalComponent} from '../pdf-modal.component';
import {ShipToComponent} from './ship-to.component';
import {EmailInvitationService} from './assets/email-invitation.service';
import {EmailInvitationComponent} from './assets/email-invitation.component';
import {MailTemplateService, MailTemplateValue} from '../mail-template.service';
import {NgForm} from '@angular/forms';
import {Button, ModalComponent} from '../modal.component';
import {fontStyles} from '../../assets/ts/font-styles';
import {environment} from '../../environments/environment';
import {PhysicianEmailAddrService} from '../physician-email-addr.service';
import { ApiService } from '../api.service';
import { AuthService } from '../auth.service';
import { email } from 'ngx-custom-validators/src/app/email/validator';

@Component({
  selector: 'app-events',
  templateUrl: './events.component.html',
  styleUrls: ['./events.component.scss'],
  providers: [
    {provide: NgbDateParserFormatter, useClass: NgbDateCustomParserFormatter},
    MaskPipe
  ]
})

export class EventsComponent implements OnInit, OnDestroy {

  public get VenueType() {
    return VenueType; 
  }

  private subscription: Subscription;
  private topics: any[];
  private displayedTopics: any[];
  public selectedTopic = null;
  private byoTopics: any;
  private isProfileLoading = false;
  public step: number;
  private physician: any;

  private physicianIdSearch: string;
  private shouldShowPhysicianSearch = false;
  shouldShowPhysicianNotFound = false;
  private eventInfo: EventInfo;

  private numberOfSessions = 1;
  // private eventDate: any;
  private shouldShowLeadTimeWarning = false;
  private shouldShowEventTimeWarning = false;

  private shouldFirePhoneChangeEvent = true;

  private states = states;

  private headerImages = headerImages;
  private selectedHeaderImage: HeaderImage;
  private long_description: string;

  private introTemplate: string;
  private isIntroEditable;

  // private long_description_delta;

  private invitationVariantId: string;
  private voiceShotVariantId: string;
  private orderId: string;
  private inviteImageUrl: string;
  private voiceShotImageUrl: string;
  private inviteProofUrl: string;
  private voiceShotProofUrl: string;
  private isWaiting = false;
  public isWholePageWaiting = false;
  // public Editor = ClassicEditor;
  private selectedProof = 0;
  // private htmlEmailUrl: SafeUrl;

  private minDate: NgbDate;

  private invitationQuantity = 0;
  private handoutQuantity = 0;
  // private attendanceCards = 0;
  private additionalInvitationQuantity = 0;
  private additionalHandoutQuantity = 0;
  private saCompanyName: string;
  private saFirstName: string;
  private saLastName: string;
  private saAddress: string;
  private saAddress2: string;
  private saCity: string;
  private saState: string;
  private saZip: string;
  private saPhone: string;
  private shippingAddress: any;
  private secondShippingAddress: any;
  private submitted = false;

  public rsvpByDateObj: NgbDate;

  // TODO should this be part of eventInfo?
  private needsApproval = true;
  private orderNumber; // for auto-approved confirmation page

  private productInteropIDs = {
    Invitation: {
      general: 'MDVIP-Invitation',
      yoch: 'MDVIP-InvitationYOCH'
    },
    VoiceShot: {
      general: 'MDVIP-VoiceShot',
      yoch: 'MDVIP-VoiceShotYOCH'
    },
    Facebook: {
      general: 'MDVIP-Facebook',
      yoch: 'MDVIP-FacebookYOCH'
    }
  };

  private formFieldStatus = [
    { // invitation
      hidden: [],
      disabled: []
    },
    { // voice shot
      hidden: ['fullName', 'specialty', 'address', 'city', 'state', 'zip', 'phone', 'fax', 'email', 'staffEmail',
        'website', 'facebook', 'twitter', 'speaker', 'patient_guests', 'eventDateText', 'dateRefresh', 'venueType', 'venue_state',
        'venue_zip', 'rsvp_phone', 'rsvpOnline', 'roomCapacity', 'webinarUrl', 'additionalInformation', 'rsvpByDate', 'topic', 'intro', 'header_image',
        'long_description'],
      disabled: ['eventDate', 'numberOfSessions', 'includeEventEndTime', 'eventStartTime', 'eventEndTime', 'venue_name', 'venue_address',
        'venue_city', 'rsvp_message', 'virtualRsvpMethod', 'title']
    },
    { // email
      hidden: ['lastName', 'fax', 'email', 'staffEmail', 'practiceRefresh', 'speakervs', 'dateRefresh', 'venueType', 'rsvp_phone',
        'roomCapacity', 'topic', 'title', 'brief_description'],
      disabled: ['fullName', 'specialty', 'address', 'city', 'state', 'zip', 'phone', 'fax', 'email', 'staffEmail',
        'website', 'facebook', 'twitter', 'speaker', 'patient_guests', 'eventDate', 'numberOfSessions', 'includeEventEndTime',
        'eventStartTime', 'eventEndTime', 'eventDateText', 'venue_name', 'venue_address', 'venue_city', 'venue_state', 'venue_zip',
        'rsvp_message', 'virtualRsvpMethod', 'webinarUrl', 'additionalInformation', 'rsvpByDate', 'header_image', 'long_description']
    }
  ];

  editorInstance;
  private isApprover = false;
  private displayedFonts: any[];

  public virtualRsvpMethod = 'url';

  @ViewChild('acc', { static: false}) accordion: NgbAccordion;
  @ViewChild('sidebar', { static: false}) sidebar: ElementRef;
  // @ViewChild('htmlEmailiFrame', {static: false}) htmlEmailiFrame: ElementRef;
  @ViewChild('emailInvitationComponent', {static: false}) emailInvitationComponent: EmailInvitationComponent;
  private validationErrors: string[];
  private timeZone: string = moment.tz.guess();

  constructor(private http: HttpClient, private cookieService: CookieService,
              private four51Service: Four51Service, private modalService: NgbModal, private maskPipe: MaskPipe,
              private domSanitizer: DomSanitizer, private changeDectector: ChangeDetectorRef, private route: ActivatedRoute,
              private mailerService: MailerService, private router: Router, private location: Location,
              private emailInvitationService: EmailInvitationService, private mailTemplateService: MailTemplateService,
              private physicianEmailAddrService: PhysicianEmailAddrService,
              private apiService: ApiService,
            private authService: AuthService) {
    this.eventInfo = {
      sessions: [
        {
          eventDate: null,
          eventStartTime: {
            hour: 1,
            minute: 0,
            hhMm: '',
            amPm: 'AM',
            moment: moment().hour(1).minute(0),
            timeZone: this.timeZone
          },
          includeEventEndTime: true,
          eventEndTime: {
            hour: 1,
            minute: 0,
            hhMm: '',
            amPm: 'AM',
            moment: moment().hour(1).minute(0),
            timeZone: this.timeZone,
            isUserSet: false
          }
        }
      ],
      rsvpOnline: false,
      patient_guests: 'guests.pdf',
      eventDateText: '',
      venue_name: '',
      venue_address_1: '',
      venue_address_2: '',
      venue_city: '',
      venue_state: '',
      venue_zip: '',
      rsvp_phone: '',
      rsvp_message: '',
      venueType: VenueType.Physical,
      webinarUrl: '',
      additionalInformation: '',
      byoSelectedTopicId: null,
      secondShipToAddress: false,
      comments: '',
      commentsName: '',
      commentsPhone: '',
      roomCapacity: '',
      eligibleProspects: '',
      pastPatients: '',
      channel: this.cookieService.get('mdvip.channel')
    };
  }

  isHidden(fieldName): boolean {
    return this.formFieldStatus[this.selectedProof].hidden.includes(fieldName);
  }
  isDisabled(fieldName): boolean {
    return this.formFieldStatus[this.selectedProof].disabled.includes(fieldName);
  }
  showPopover(p, fieldName) {
    if (this.isDisabled(fieldName)) {
      p.open();
    }
  }

  async ngOnInit() {
    // console.log('ngOnInit');
    const type = this.route.snapshot.paramMap.get('type') || 'general';

    const res: any = await this.http.get('./assets/json/topics.json').toPromise();
    this.topics = res;
    this.displayedTopics = this.topics.filter(x => x.type === type);
    this.displayedTopics
      .filter(x => x.topics)
      .forEach(x => x.byoTopics = this.topics.filter( y => x.topics.includes(y.id)));

    /*let emailFile;
    switch (type) {
      case 'yoch': {
        emailFile = 'email-yoch.html';
        break;
      }
      default: {
        emailFile = 'email.html';
        break;
      }
    }
    this.http.get('./assets/html/' + emailFile, {responseType: 'text'})
      .subscribe(res  => {
        this.htmlEmailTemplate = res;
      });*/
    this.subscription = new Subscription();

    const paramMapSubscription = this.route.paramMap.subscribe((params: ParamMap) => {
      const orderid = params.get('orderid');
      const action = params.get('action');
      const topicid = params.get('topicid');
      const step = params.get('step');
      const typeParam = params.get('type');

      if (orderid && action) {
        this.startAction(orderid, action);
      } else if (topicid) {
        this.selectTopic(+topicid);
      } else if (step) {
        this.step = +step;
        this.changeDectector.detectChanges();
      } else if (typeParam) {
        this.step = 1;
        this.selectedTopic = null;
        this.displayedTopics = this.topics.filter(x => x.type === typeParam);
      } else {
        this.step = 1;
        this.selectedTopic = null;
        this.displayedTopics = this.topics.filter(x => x.type === 'general');
      }
    });
    this.subscription.add(paramMapSubscription);

    const Font = Quill.import('formats/font');
    if (this.isApprover) {
      this.displayedFonts = fontStyles;
    } else {
      this.displayedFonts = fontStyles.filter(x => !x.approverOnly);
    }
    Font.whitelist = this.displayedFonts.map(x => x.name);
    Quill.register(Font, true);

    const now = moment().add(1, 'days');
    this.minDate = new NgbDate(now.year(), now.month() + 1, now.date());

    const user = await this.apiService.getUser().toPromise();
    if (user.physician) {
      this.physician = user.physician;
    }
    
  }

  startAction(orderId, action) {
    this.step = 2;
    this.isWholePageWaiting = true;
    this.loadFromOrder(orderId, action);
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }

  showPreview(e, file, title) {
    e.preventDefault();
    const pdfSrc = '/assets/pdf/' + file;
    const modalRef = this.modalService.open(PdfModalComponent, { size: 'lg', backdrop: 'static', scrollable: true });
    modalRef.componentInstance.title = title;
    modalRef.componentInstance.pdfSrc = pdfSrc;
  }

  async selectTopic(id) {

    this.step = 2;

    /** practice fields **/
    this.selectedTopic = this.topics.filter(x => x.id === id)[0];
    this.eventInfo.topicId = id;
    this.eventInfo.type = this.selectedTopic.type;
    if (this.selectedTopic.topics) {
      this.byoTopics = this.topics.filter( x => this.selectedTopic.topics.includes(x.id) );
    } else {
      this.byoTopics = null;
    }
    this.needsApproval = this.selectedTopic.needs_approval;
    // console.log(id);
    this.eventInfo.fullName = this.physician.fullName; //this.getCustomFieldValue('fullName');
    this.eventInfo.LastName = this.physician.lastname; //this.mdvipUser.LastName;
    this.eventInfo.Specialty = this.physician.specialty; //this.getCustomFieldValue('Specialty');
    this.eventInfo.Address = this.physician.address1; //this.getCustomFieldValue('Address');
    this.eventInfo.Address2 = this.physician.address2; //this.getCustomFieldValue('Address2');
    this.eventInfo.City = this.physician.city; //this.getCustomFieldValue('City');
    this.eventInfo.State = this.physician.state; //this.getCustomFieldValue('State');
    this.eventInfo.Zip = this.physician.zip; //this.getCustomFieldValue('Zip');
    this.eventInfo.Phone = this.maskPipe.transform(this.physician.phone /*this.mdvipUser.Phone*/, '000.000.0000');
    this.eventInfo.fax = this.maskPipe.transform(this.physician.fax /*this.getCustomFieldValue('fax')*/, '000.000.0000');
    this.eventInfo.email = this.physician.email; //this.mdvipUser.Email;
    this.eventInfo.staffEmail = this.physician.staffEmail; //this.getCustomFieldValue('staffEmail');
    this.eventInfo.Website = this.physician.website; //this.getCustomFieldValue('Website');
    this.eventInfo.facebook = this.physician.facebook; //this.getCustomFieldValue('facebook');
    this.eventInfo.twitter = this.physician.twitter; //this.getCustomFieldValue('twitter');
    /** end practice fields **/

    /** data fields **/
    this.eventInfo.physicianId = this.physician.physicianId; //this.getCustomFieldValue('physicianId');
    this.eventInfo.memberServiceState = this.physician.memberServiceState; //this.getCustomFieldValue('memberServiceState');
    this.eventInfo.membershipTier = this.physician.membershipTier; //this.getCustomFieldValue('membershipTier');
    this.eventInfo.pdmEmail = this.physician.pdmEmail; //this.getCustomFieldValue('pdmEmail');
    this.eventInfo.physicianStatusType = this.physician.physicianStatusType; //this.getCustomFieldValue('physicianStatusType');
    this.eventInfo.practiceStatus = this.physician.practiceStatus; //this.getCustomFieldValue('practiceStatus');
    this.eventInfo.ptmEmail = this.physician.ptmEmail; //this.getCustomFieldValue('ptmEmail');
    this.eventInfo.relationshipStatus = this.physician.relationShipStatus; //this.getCustomFieldValue('relationshipStatus');
    /** end data fields **/

    /** topic fields **/
    this.eventInfo.long_description_html = this.selectedTopic.long_description;
    this.clearUndoHistory();
    this.eventInfo.title = this.selectedTopic.title;
    this.eventInfo.brief_description = this.selectedTopic.brief_description;
    this.introTemplate = this.selectedTopic.intro;
    this.isIntroEditable = this.selectedTopic.editable_fields.includes('intro');
    if (this.isIntroEditable) {
      this.resetIntro();
    } // otherwise, we'll fill in from introTemplate when we view proof
    this.eventInfo.header_image = this.selectedTopic.header_image;
    this.eventInfo.logo = this.selectedTopic.logo;
    this.selectedHeaderImage = this.headerImages.filter( x => x.file === this.eventInfo.header_image)[0];
    /** end topic fields **/

    /** venue fields **/
    this.eventInfo.venue_address_1 = this.physician.address1; //this.getCustomFieldValue('Address');
    this.eventInfo.venue_address_2 = this.physician.address2; //this.getCustomFieldValue('Address2');
    this.eventInfo.venue_city = this.physician.city; //this.getCustomFieldValue('City');
    this.eventInfo.venue_state = this.physician.state; //this.getCustomFieldValue('State');
    this.eventInfo.venue_zip = this.physician.zip; //this.getCustomFieldValue('Zip');
    this.eventInfo.rsvp_phone = this.maskPipe.transform(this.physician.phone /*this.mdvipUser.Phone*/, '000.000.0000');
    // this.eventInfo.rsvp_message = 'RSVP by calling the office at ' + this.eventInfo.rsvp_phone;
    this.setRsvpMessage();
    /** end venue fields **/

    // force accordion to be recognized after becoming unhidden
    // combined with {static: false} in @ViewChild
    this.changeDectector.detectChanges();
    this.accordion.expand('event-info');
    /*if (this.selectedTopic.editable_fields.length > 0) {
      this.accordion.expand('topic-info');
    } else {
      this.accordion.expand('event-info');
    }*/
    if (this.eventInfo.byoSelectedTopicId) {
      this.topicChanged(null);
    }
    this.getInvitation();
  }

  panelChanged(e) {
    // this.sidebar.nativeElement.scrollTo(0, 0);
    // fix for IE 11 and Edge
    this.sidebar.nativeElement.scrollTop = 0;
  }

  loadFromOrder(orderId, action) {
    this.isWaiting = true;
    // this.four51Service.getOrderById(orderId).subscribe((order: any) => {
    //   this.isWholePageWaiting = false;
    //   if (action === 'edit') {
    //     this.orderId = order.ID;
    //     this.invitationVariantId = order.LineItems[0].Variant.InteropID;
    //     this.inviteImageUrl = order.LineItems[0].Variant.PreviewUrl + '?r=' + Math.random();
    //     this.inviteProofUrl = order.LineItems[0].Variant.ProofUrl + '&r=' + Math.random();
    //     if (order.LineItems.length > 1) {
    //       this.deleteExtraLineItems(order);
    //     }
    //     // this.mdvipUser.CurrentOrderID = this.orderId;
    //     // this.four51Service.setCurrentOrder(this.orderId).subscribe(res => {
    //     //   // console.log('user currentorder updated');
    //     // });
    //   }
    //   const dataString = this.getOrderFieldValue('data', order );
    //   const data = JSON.parse(dataString);
    //   this.selectedTopic = this.topics.filter(x =>  x.id === data.topicId)[0];
    //   this.eventInfo.topicId = data.topicId;
    //   if (this.selectedTopic.topics) {
    //     this.byoTopics = this.topics.filter( x => this.selectedTopic.topics.includes(x.id) );
    //   } else {
    //     this.byoTopics = null;
    //   }
    //   this.eventInfo.type = data.type || 'general';
    //   this.needsApproval = this.selectedTopic.needs_approval;
    //   if (action === 'clone') {
    //     this.shouldShowPhysicianSearch = true;
    //     // need these for case when clone is called from this component (i.e. confirmation)
    //     this.orderId = null;
    //     this.invitationVariantId = null;
    //     this.inviteImageUrl = null;
    //     this.inviteProofUrl = null;
    //     this.physicianIdSearch = '';
    //     this.eventInfo.fullName = '';
    //     this.eventInfo.LastName = '';
    //     this.eventInfo.Specialty = '';
    //     this.eventInfo.Address = '';
    //     this.eventInfo.Address2 = '';
    //     this.eventInfo.City = '';
    //     this.eventInfo.State = '';
    //     this.eventInfo.Zip = '';
    //     this.eventInfo.Phone = '';
    //     this.eventInfo.fax = '';
    //     this.eventInfo.email = '';
    //     this.eventInfo.staffEmail = '';
    //     this.eventInfo.Website = '';
    //     this.eventInfo.facebook = '';
    //     this.eventInfo.twitter = '';

    //     /** data fields **/
    //     this.eventInfo.physicianId = null;
    //     this.eventInfo.memberServiceState = '';
    //     this.eventInfo.membershipTier = '';
    //     this.eventInfo.pdmEmail = '';
    //     this.eventInfo.physicianStatusType = '';
    //     this.eventInfo.practiceStatus = '';
    //     this.eventInfo.ptmEmail = '';
    //     this.eventInfo.relationshipStatus = '';
    //     /** end data fields **/
    //   } else {
    //     this.eventInfo.fullName = this.getOrderFieldValue('fullName', order);
    //     this.eventInfo.LastName = this.getOrderFieldValue('LastName', order);
    //     this.eventInfo.Specialty = this.getOrderFieldValue('Specialty', order);
    //     this.eventInfo.Address = this.getOrderFieldValue('Address', order);
    //     this.eventInfo.Address2 = this.getOrderFieldValue('Address2', order);
    //     this.eventInfo.City = this.getOrderFieldValue('City', order);
    //     this.eventInfo.State = this.getOrderFieldValue('State', order);
    //     this.eventInfo.Zip = this.getOrderFieldValue('Zip', order);
    //     this.eventInfo.Phone = this.getOrderFieldValue('Phone', order);
    //     this.eventInfo.fax = this.getOrderFieldValue('fax', order);
    //     this.eventInfo.email = data.email || '';
    //     this.eventInfo.staffEmail = data.staffEmail || '';
    //     this.eventInfo.Website = this.getOrderFieldValue('Website', order);
    //     this.eventInfo.facebook = this.getOrderFieldValue('facebook', order);
    //     this.eventInfo.twitter = this.getOrderFieldValue('twitter', order);

    //     /** data fields **/
    //     this.eventInfo.physicianId = data.physicianId || '';
    //     this.eventInfo.memberServiceState = data.memberServiceState || '';
    //     this.eventInfo.membershipTier = data.membershipTier || '';
    //     this.eventInfo.pdmEmail = data.pdmEmail || '';
    //     this.eventInfo.physicianStatusType = data.physicianStatusType || '';
    //     this.eventInfo.practiceStatus = data.practiceStatus || '';
    //     this.eventInfo.ptmEmail = data.ptmEmail || '';
    //     this.eventInfo.relationshipStatus = data.relationshipStatus || '';
    //     /** end data fields **/
    //   }

    //   /** data fields **/
    //   this.eventInfo.byoSelectedTopicId = data.byoSelectedTopicId || 0;
    //   /** end data fields **/

    //   this.long_description = this.getOrderFieldValue('long_description', order);
    //   this.eventInfo.long_description_html = this.getOrderFieldValue('long_description_html', order);
    //   // console.log(this.eventInfo.long_description_html);
    //   this.clearUndoHistory();
    //   this.eventInfo.title = this.getOrderFieldValue('title', order);
    //   this.eventInfo.brief_description = this.getOrderFieldValue('brief_description', order);
    //   // console.log(this.byoSelectedTopicId);
    //   if (this.eventInfo.byoSelectedTopicId === 0) {
    //     this.introTemplate = this.selectedTopic.intro;
    //   } else {
    //     const byoTopic = this.topics.filter(x =>  x.id === this.eventInfo.byoSelectedTopicId)[0];
    //     this.introTemplate = byoTopic.intro;
    //   }
    //   this.isIntroEditable = this.selectedTopic.editable_fields.includes('intro');
    //   this.eventInfo.intro = this.getOrderFieldValue('intro', order);
    //   this.eventInfo.header_image = this.getOrderFieldValue('header_image', order);
    //   this.eventInfo.logo = this.getOrderFieldValue('logo', order);
    //   this.selectedHeaderImage = this.headerImages.filter( x => x.file === this.eventInfo.header_image)[0];

    //   this.eventInfo.speaker = this.getOrderFieldValue('speaker', order);
    //   this.eventInfo.speakervs = this.getOrderFieldValue('speakervs', order);
    //   this.eventInfo.patient_guests = this.getOrderFieldValue('patient_guests', order);
    //   if (data.sessions) {
    //     this.eventInfo.sessions = [];
    //     for (const session of data.sessions) {
    //       this.eventInfo.sessions.push({
    //         eventDate: session.eventDate,
    //         eventStartTime: {
    //           hour: session.eventStartTime.hour,
    //           minute: session.eventStartTime.minute,
    //           hhMm: session.eventStartTime.hhMm,
    //           amPm: session.eventStartTime.amPm,
    //           moment: moment(session.eventStartTime.moment),
    //           timeZone: session.eventStartTime.timeZone
    //         },
    //         includeEventEndTime: session.includeEventEndTime,
    //         eventEndTime: {
    //           hour: session.eventEndTime.hour,
    //           minute: session.eventEndTime.minute,
    //           hhMm: session.eventEndTime.hhMm,
    //           amPm: session.eventEndTime.amPm,
    //           moment: moment(session.eventEndTime.moment),
    //           timeZone: session.eventStartTime.timeZone,
    //           isUserSet: session.eventEndTime.isUserSet || false
    //         }
    //       });
    //     }
    //     this.numberOfSessions = this.eventInfo.sessions.length;
    //   }

    //   const softReturn = String.fromCharCode(8232);
    //   const softReturnRegExp = new RegExp(softReturn, 'g');
    //   let eventDateText = this.getOrderFieldValue('event_date_text', order);
    //   eventDateText = eventDateText.replace(softReturnRegExp, '\n');
    //   this.eventInfo.eventDateText = eventDateText;

    //   this.eventInfo.venue_name = this.getOrderFieldValue('venue_name', order);
    //   this.eventInfo.venue_address_1 = this.getOrderFieldValue('venue_address_1', order);
    //   this.eventInfo.venue_address_2 = this.getOrderFieldValue('venue_address_2', order);
    //   this.eventInfo.venue_city = this.getOrderFieldValue('venue_city', order);
    //   this.eventInfo.venue_state = this.getOrderFieldValue('venue_state', order);
    //   this.eventInfo.venue_zip = this.getOrderFieldValue('venue_zip', order);
    //   this.shouldFirePhoneChangeEvent = false;
    //   this.eventInfo.rsvp_phone = this.getOrderFieldValue('rsvp_phone', order);
    //   this.eventInfo.rsvp_message = this.getOrderFieldValue('rsvp_message', order);

    //   this.eventInfo.venueType = VenueType[this.getOrderFieldValue('venueType', order)];
    //   this.eventInfo.webinarUrl = this.getOrderFieldValue('webinarUrl', order);
    //   this.eventInfo.additionalInformation = this.getOrderFieldValue('additionalInformation', order);
    //   this.eventInfo.additionalInformation = this.eventInfo.additionalInformation.replace(softReturnRegExp, '\n');
    //   this.virtualRsvpMethod = (this.eventInfo.rsvp_message.includes(this.eventInfo.rsvp_phone)) ? 'phone' : 'url';
    //   this.eventInfo.rsvpByDate = this.getOrderFieldValue('rsvpByDate', order);
    //   const rsvpByDateString = this.getOrderFieldValue('rsvpByDate', order).replace('RSVP by ', '');
    //   if (rsvpByDateString) {
    //     let rsvpByDateMoment = moment(rsvpByDateString, 'dddd, MMMM D');
    //     if (!rsvpByDateMoment.isValid()) { // if date is in following year
    //       rsvpByDateMoment = moment(rsvpByDateString + ' ' + (moment().year() + 1), 'dddd, MMMM D YYYY');
    //     }
    //     this.rsvpByDateObj = new NgbDate(rsvpByDateMoment.year(), rsvpByDateMoment.month() + 1, rsvpByDateMoment.date());
    //   }
      
    //   this.eventInfo.rsvpOnline = data.rsvpOnline || false;
    //   this.eventInfo.roomCapacity = data.roomCapacity || '';

    //   // force accordion to be recognized after becoming unhidden
    //   // combined with {static: false} in @ViewChild
    //   this.changeDectector.detectChanges();
    //   if (action === 'clone') {
    //     this.accordion.expand('practice-info');
    //   } else {
    //     this.accordion.expand('event-info');
    //     /*if (this.selectedTopic.editable_fields.length > 0) {
    //       this.accordion.expand('topic-info');
    //     } else {
    //       this.accordion.expand('event-info');
    //     }*/
    //   }

    //   if (action === 'copy' || action === 'clone') {
    //     this.getInvitation();
    //   }
    // });
  }

  async deleteExtraLineItems(order: any) {
    // this should only happen if there was an error submitting the order
    for (let i = 1; i < order.LineItems.length; i++) {
      const lineItemId = order.LineItems[i].ID;
      let response: any;
      // response = await this.four51Service.deleteOrder(order.ID, lineItemId).toPromise();
      if (order.LineItems[i].Variant) {
        const variantId = order.LineItems[i].Variant.InteropID;
        const productInteropId = order.LineItems[i].Variant.ProductInteropID;
        if (variantId !== this.invitationVariantId) {
          response = await this.four51Service.deleteVariant(variantId, productInteropId).toPromise();
        }
      }
    }
  }

  async getPhysicianProfle() {
    this.shouldShowPhysicianNotFound = false;
    this.isProfileLoading = true;
    const profile = await this.four51Service.getPhysicianProfile(this.physicianIdSearch)
      .toPromise();
    // console.log(profile);
    this.isProfileLoading = false;
    if (profile.Status) {
      // old version
      if (profile.Status !== 'Error') {
        const physicianDetails = profile.PhysicianDetails[0];
        const physicianId = physicianDetails.PhysicianID__c;
        const fullname = physicianDetails.Name;
        let address1 = null;
        let address2 = null;
        if (physicianDetails.BillingStreet) {
          const address = physicianDetails.BillingStreet.split('\r\n');
          address1 = address[0];
          if (address.length > 1) {
            address2 = address[1];
          }
        }
        let practiceStatus = null;
        if (physicianDetails.Practice_Status__c) {
          practiceStatus = physicianDetails.Practice_Status__c.toLowerCase().split(' ').map(word => {
            return word.charAt(0).toUpperCase() + word.slice(1);
          }).join('-');
        }
        let pdmEmail = null;
        let ptmEmail = null;
        if (profile.AccountTeam) {
          for (const accountTeam of profile.AccountTeam) {
            if (accountTeam.Role) {
              if (accountTeam.Role === 'PDM') {
                pdmEmail = (accountTeam.Email) ? accountTeam.Email : null;
              } else if (accountTeam.Role === 'PTM') {
                ptmEmail = (accountTeam.Email) ? accountTeam.Email : null;
              }
            }
          }
        }
        let staffEmail = null;
        if (profile.StaffTeam) {
          for (const staffTeam of profile.StaffTeam) {
            if (staffTeam.Email) {
              staffEmail = staffTeam.Email;
              break; // only use the first one
            }
          }
        }
        /** practice fields **/
        this.eventInfo.fullName = fullname;
        this.eventInfo.LastName = physicianDetails.Last_Name__c || '';
        this.eventInfo.Specialty = physicianDetails.Specialty__c || '';
        this.eventInfo.Address = address1;
        this.eventInfo.Address2 = address2;
        this.eventInfo.City = physicianDetails.BillingCity || '';
        this.eventInfo.State = physicianDetails.BillingState || '';
        this.eventInfo.Zip = physicianDetails.BillingPostalCode || '';
        this.eventInfo.Phone = this.maskPipe.transform(physicianDetails.Phone || '', '000.000.0000');
        this.eventInfo.fax = this.maskPipe.transform(physicianDetails.Fax || '', '000.000.0000');
        this.eventInfo.email = physicianDetails.Contact_Email__c || '';
        this.eventInfo.staffEmail = staffEmail;
        this.eventInfo.Website = physicianDetails.Website || '';
        this.eventInfo.facebook = (physicianDetails.Facebook_Url__c) ? this.tldOnly(physicianDetails.Facebook_Url__c) : 'facebook.com/mdvip';
        this.eventInfo.twitter = (physicianDetails.Twitter_Url__c) ? this.tldOnly(physicianDetails.Twitter_Url__c) : 'twitter.com/mdvip';
        /** end practice fields **/
  
        /** data fields **/
        this.eventInfo.physicianId = physicianId;
        this.eventInfo.memberServiceState = (physicianDetails.Member_Service_State__c) ?
          physicianDetails.Member_Service_State__c.toUpperCase() : '';
        this.eventInfo.membershipTier = physicianDetails.Membership_Tier__c || '';
        this.eventInfo.pdmEmail = pdmEmail;
        this.eventInfo.physicianStatusType = physicianDetails.Physician_Status_Type__c || '';
        this.eventInfo.practiceStatus = practiceStatus;
        this.eventInfo.ptmEmail = ptmEmail;
        this.eventInfo.relationshipStatus = physicianDetails.MDVIP_Relationship_Status__c || '';
        /** end data fields **/
      } else {
        this.shouldShowPhysicianNotFound = true;
      }
    } else if (profile.StatusCode) {
      // new version
      if (profile.StatusCode === '200') {
        const physicianDetails = profile.PhysicianDetails;
        const physicianId = physicianDetails.PhysicianId;
        const fullname = physicianDetails.Name;
        let address1 = null;
        let address2 = null;
        if (physicianDetails.Billingstreet) {
          const address = physicianDetails.Billingstreet.split('\r\n');
          address1 = address[0];
          if (address.length > 1) {
            address2 = address[1];
          }
        }
        let practiceStatus = null;
        if (physicianDetails.PracticeStatus) {
          practiceStatus = physicianDetails.PracticeStatus.toLowerCase().split(' ').map(word => {
            return word.charAt(0).toUpperCase() + word.slice(1);
          }).join('-');
        }
        let pdmEmail = null;
        let ptmEmail = null;
        if (profile.AccountTeam) {
          for (const accountTeam of profile.AccountTeam) {
            if (accountTeam.Role) {
              if (accountTeam.Role === 'PDM') {
                pdmEmail = (accountTeam.Email) ? accountTeam.Email : null;
              } else if (accountTeam.Role === 'PTM') {
                ptmEmail = (accountTeam.Email) ? accountTeam.Email : null;
              }
            }
          }
        }
        let staffEmail = null;
        if (profile.StaffTeam) {
          for (const staffTeam of profile.StaffTeam) {
            if (staffTeam.Email) {
              staffEmail = staffTeam.Email;
              break; // only use the first one
            }
          }
        }
        /** practice fields **/
        this.eventInfo.fullName = fullname;
        this.eventInfo.LastName = physicianDetails.LastName || '';
        this.eventInfo.Specialty = physicianDetails.Specialty || '';
        this.eventInfo.Address = address1;
        this.eventInfo.Address2 = address2;
        this.eventInfo.City = physicianDetails.BillingCity || '';
        this.eventInfo.State = physicianDetails.BillingState || '';
        this.eventInfo.Zip = physicianDetails.Billingpostalcode || '';
        this.eventInfo.Phone = this.maskPipe.transform(physicianDetails.Phone || '', '000.000.0000');
        this.eventInfo.fax = this.maskPipe.transform(physicianDetails.Fax || '', '000.000.0000');
        this.eventInfo.email = physicianDetails.ContactEmail || '';
        this.eventInfo.staffEmail = staffEmail;
        this.eventInfo.Website = physicianDetails.Website || '';
        this.eventInfo.facebook = (physicianDetails.FacebookUrl) ? this.tldOnly(physicianDetails.FacebookUrl) : 'facebook.com/mdvip';
        this.eventInfo.twitter = (physicianDetails.TwitterUrl) ? this.tldOnly(physicianDetails.TwitterUrl) : 'twitter.com/mdvip';
        /** end practice fields **/
  
        /** data fields **/
        this.eventInfo.physicianId = physicianId;
        this.eventInfo.memberServiceState = (physicianDetails.MemberServiceState) ?
          physicianDetails.MemberServiceState.toUpperCase() : '';
        this.eventInfo.membershipTier = physicianDetails.MembershipTier || '';
        this.eventInfo.pdmEmail = pdmEmail;
        this.eventInfo.physicianStatusType = physicianDetails.PhysicianStatusType || '';
        this.eventInfo.practiceStatus = practiceStatus;
        this.eventInfo.ptmEmail = ptmEmail;
        this.eventInfo.relationshipStatus = physicianDetails.MDVIPRelationshipStatus || '';
        /** end data fields **/
      } else {
        this.shouldShowPhysicianNotFound = true;
      }
    }
    
  }

  tldOnly(url) {
    return url.toLowerCase()
      .replace('www.', '')
      .replace('http://', '')
      .replace('https://', '');
  }

  topicChanged(e) {
    const byoTopic = this.topics.filter(x =>  x.id === this.eventInfo.byoSelectedTopicId)[0];
    if (byoTopic) {
      this.eventInfo.long_description_html = byoTopic.long_description;
      this.clearUndoHistory();
      this.eventInfo.title = byoTopic.title;
      this.eventInfo.brief_description = byoTopic.brief_description;
      this.introTemplate = byoTopic.intro;
      this.resetIntro(); // isIntroEditable will always be true if topicChanged is called
      this.eventInfo.header_image = byoTopic.header_image;
      this.selectedHeaderImage = this.headerImages.filter( x => x.file === this.eventInfo.header_image)[0];
    } else {
      this.eventInfo.long_description_html = '';
      this.clearUndoHistory();
      this.eventInfo.title = this.selectedTopic.title;
      this.eventInfo.brief_description = '';
      this.introTemplate = '';
      this.resetIntro();
      this.eventInfo.header_image = '';
      this.selectedHeaderImage = null;
    }

  }

  headerImageClicked(headerImage) {
    this.selectedHeaderImage = headerImage;
    this.eventInfo.header_image = this.selectedHeaderImage.file;
  }

  getOrderFieldValue(name, order): string {
    const invitationLineItem = order.LineItems
      .find(x => x.Product.InteropID === 'MDVIP-Invitation' || x.Product.InteropID === 'MDVIP-InvitationYOCH');
    /*let invitationLineItem;
    for (const lineItem of order.LineItems) {
      if (lineItem.Product.InteropID === 'MDVIP-Invitation' || lineItem.Product.InteropID === 'MDVIP-InvitationYOCH') {
        invitationLineItem = lineItem;
        break;
      }
    }*/
    if (invitationLineItem) {
      let field;
      if (invitationLineItem.Variant.Specs) {
        field = invitationLineItem.Variant.Specs[name];
      } else {
        field = invitationLineItem.Specs[name];
      }
      if (field) {
        const returnString = field.Value || '';
        return returnString ;
      } else {
        return '';
      }
    } else { // no invitation line item
      return '';
    }

  }

  /* quill */
  contentChanged(e) {
    // console.log(JSON.stringify(e.content));
    // this.long_description_delta = e.content;
    // console.log(this.long_description_html);
  }

  editorCreated(e) {
    // console.log('editor created');
    this.editorInstance = e;
    this.clearUndoHistory();
    /*setTimeout(function() {
      // const contents = this.editorInstance.clipboard.convert('hello <span class="ql-font-brown-italic">world</span>');
      // this.editorInstance.setContents(contents);
      // console.log(this.long_description_html);
      this.long_description_html = 'hello <span class="ql-font-brown-italic">world</span>';
    }.bind(this), 1000);*/

    // console.log('editor created');
    // console.log(e);
  }

  insertColumnBreak() {
    let cursorPosition;
    if (this.editorInstance.getSelection()) {
      cursorPosition = this.editorInstance.getSelection().index;
    } else {
      cursorPosition = 0;
    }
    this.editorInstance.insertText(cursorPosition, '\n[Column Break]', 'user'); // user makes sure update event occurs
    cursorPosition += 15;
    this.editorInstance.setSelection(cursorPosition, 0, 'user');
    this.editorInstance.formatLine(cursorPosition, 0, 'list', false, 'user');
  }

  applyStyle(style) {
    // console.log(style);
    const cursorPosition = this.editorInstance.getSelection().index;
    const selectionEnd = this.editorInstance.getSelection().length;
  }

  undo() {
    this.editorInstance.history.undo();
  }

  clearUndoHistory() {
    if (this.editorInstance) {
      setTimeout(() => {this.editorInstance.history.clear(); }, 0);
    }
  }

  htmlToPageflex(html: string) {
    const pfheader = '<?Pageflex pf_xfp_ver="1"?>';
    const column_break = String.fromCharCode(61447);
    this.long_description = pfheader;
    /*
    // https://stackoverflow.com/questions/50356408/upgrading-to-angular-6-x-gives-uncaught-referenceerror-global-is-not-defined
    const parser = new xml2js.Parser({ strict: false, trim: true });
    parser.parseString('<div>' + this.long_description_html + '</div>', function (err, result) {
      for (const key of Object.keys(result.DIV)) {
        console.log(JSON.stringify(result.DIV));
        this.processHtmlElement(key, result.DIV[key]);
      }
    }.bind(this));
     */
    const domparser = new DOMParser();
    const doc = domparser.parseFromString('<div id="content">' + this.eventInfo.long_description_html + '</div>', 'text/html');
    const children = doc.getElementById('content').childNodes;
    for (let i = 0; i < children.length; i++) {
      this.processHtmlElement(children[i]);
    }
    // console.log(this.long_description);
    this.long_description = this.long_description.replace(/\[Column Break\]/g, column_break);
  }

  processHtmlElement(node) {
    // console.log(node.nodeName);
    let lineSpacing = '59959';
    if (node.innerHTML === '[Column Break]') {
      lineSpacing = '0';
    }
    const pfpara = '<PF_Para_Base auto_line_spacing="false" line_spacing="' + lineSpacing + '" rule_before_overprint="false" ' +
      'rule_after_overprint="false" hyph_level="0">';
    const pfparabullet = '<PF_Para_Base auto_line_spacing="false" line_spacing="59959" rule_before_overprint="false" ' +
      'rule_after_overprint="false" hyph_level="0" left_indent="31750" tab_list="x_position=31750 align=left">';
    const pfchar = '<_char font_name="/Proxima Nova Rg" text_color="MDVIP_BRONZE" font_size="37033">';
    const tab = String.fromCharCode(61446);
    const bullet = String.fromCharCode(8226);

    switch (node.nodeName) {
      case '#text':
        this.long_description += node.nodeValue;
        break;
      case 'P':
        this.long_description += pfpara + pfchar ;
        for (let i = 0; i < node.childNodes.length; i++) {
          this.processHtmlElement(node.childNodes[i]);
        }
        this.long_description += '</_char></PF_Para_Base>';
        break;
      case 'UL': case 'OL':
        for (let i = 0; i < node.childNodes.length; i++) {
          this.processHtmlElement(node.childNodes[i]);
        }
        break;
      case 'LI':
        let listCharacter;
        if (node.parentNode.nodeName === 'UL') {
          listCharacter = bullet;
        } else {
          let i = 1;
          let test = node;
          while ( (test = test.previousSibling) != null ) {
            i++;
          }
          listCharacter =  i + '.';
        }
        this.long_description += pfparabullet + pfchar ;
        // this.long_description += listCharacter + tab + node.innerHTML;
        this.long_description += listCharacter + tab;
        for (let i = 0; i < node.childNodes.length; i++) {
          this.processHtmlElement(node.childNodes[i]);
        }
        this.long_description += '</_char></PF_Para_Base>';
        break;
      case 'SPAN':
        const cls = node.getAttribute('class');
        if (cls) {
          const fontStyle = fontStyles.find(x => x.class === cls);
          if (fontStyle) {
            this.long_description += '<_char font_name="/' + fontStyle.pffont + '" ';
            if (fontStyle.colorName) {
              this.long_description += 'text_color="' + fontStyle.pfcolor + '" ';
            }
            this.long_description += 'font_size="37033" bold="' + fontStyle.pfbold + '" italic="' + fontStyle.pfitalic + '" >';
          }
        }
        this.long_description += node.innerHTML;
        if (cls) {
          this.long_description += '</_char>';
        }

        break;
    }

  }

  isObject(obj) {
    return obj === Object(obj);
  }

  onEventDateSelect(e) {
    const eventDateMoment = moment(this.ngbDateToString(this.eventInfo.sessions[0].eventDate), 'YYYY-MM-DD');
    const leadTime = eventDateMoment.diff(moment(), 'days');
    this.shouldShowLeadTimeWarning = (leadTime < 14);
    this.updateEventDateText();
    if (this.isIntroEditable) {
      this.resetIntro();
    }
  }

  numberOfSessionsChanged(e) {
    // console.log(this.numberOfSessions);
    if (this.numberOfSessions > 1) {
      this.eventInfo.sessions[0].includeEventEndTime = true;
    }
    if (this.eventInfo.sessions.length > this.numberOfSessions) {
      // length 3, sessions is 2, pop 1
      for (let i = 0; i <= (this.eventInfo.sessions.length - this.numberOfSessions); i++) {
        this.eventInfo.sessions.pop();
      }
    }
    if (this.numberOfSessions > this.eventInfo.sessions.length) {
      for (let i = 0; i <= (this.numberOfSessions - this.eventInfo.sessions.length); i++) {
        this.eventInfo.sessions.push({
          eventDate: this.eventInfo.sessions[0].eventDate,
          eventStartTime: {
            hour: 1,
            minute: 0,
            hhMm: '',
            amPm: 'AM',
            moment: moment().hour(1).minute(0),
            timeZone: this.timeZone
          },
          includeEventEndTime: true,
          eventEndTime: {
            hour: 2,
            minute: 0,
            hhMm: '',
            amPm: 'AM',
            moment: moment().hour(2).minute(0),
            timeZone: this.timeZone,
            isUserSet: false
          }
        });
      }
    }
    this.updateEventDateText();
  }

  eventTimeModelChanged(eventTime) {
    // append leading zero if first digit is not zero or one
    if (eventTime.hhMm.length === 1) {
      const hour = parseInt(eventTime.hhMm.substring(0, 1), 10);
      if (!isNaN(hour)) {
        if (hour > 1) {
          eventTime.hhMm = '0'.concat(eventTime.hhMm);
        }
      }
    }
  }

  eventTimeChanged(session, isStartTime) {
    let eventTime;
    if (isStartTime) {
      eventTime = session.eventStartTime;
    } else {
      // console.log('end time changed');
      session.eventEndTime.isUserSet = true;
      eventTime = session.eventEndTime;
    }
    this.parseEventTime(eventTime);
    if (isStartTime && !session.eventEndTime.isUserSet) {
      this.setEventEndTime(session);
    }
    this.updateEventDateText();
    if (this.isIntroEditable) {
      this.resetIntro();
    }
  }

  amPmClicked(session, isStartTime) {
    let eventTime;
    if (isStartTime) {
      eventTime = session.eventStartTime;
    } else {
      session.eventEndTime.isUserSet = true;
      eventTime = session.eventEndTime;
    }
    eventTime.amPm = (eventTime.amPm === 'AM') ? 'PM' : 'AM';
    this.parseEventTime(eventTime);
    if (isStartTime && !session.eventEndTime.isUserSet) {
      session.eventEndTime.amPm = eventTime.amPm;
      this.parseEventTime(session.eventEndTime);
    }
    this.updateEventDateText();
    if (this.isIntroEditable) {
      this.resetIntro();
    }
  }

  parseEventTime(eventTime) {
    const hhMmArray = eventTime.hhMm.split(':');
    let hours = 1;
    let minutes = 0;
    let m = moment().hours(1).minutes(0);
    let stringShouldChange = false;
    if (hhMmArray.length > 1) {
      let parsedHours = parseInt(hhMmArray[0], 10);
      let parsedMinutes = parseInt(hhMmArray[1], 10);
      if (!isNaN(parsedHours) && !isNaN(parsedMinutes))  {
        if (parsedMinutes > 59) {
          stringShouldChange = true;
          parsedMinutes = 59;
        }
        if (parsedHours > 12) {
          stringShouldChange = true;
        } else {
          if (parsedHours === 12) {
            if (eventTime.amPm === 'AM') {
              stringShouldChange = true;
              parsedHours = 0;
            }
          } else { // parsedHours < 12
            if (parsedHours === 0) {
              stringShouldChange = true;
            } else {
              if (eventTime.amPm === 'PM') {
                parsedHours += 12;
              }
            }
          }
        }
        hours = parsedHours;
        minutes = parsedMinutes;
        m = moment().hours(hours).minutes(minutes).seconds(0);
      } else {
        eventTime.hhMm = '';
      }
    } else {
      eventTime.hhMm = '';
    }
    eventTime.hour = hours;
    eventTime.minute = minutes;
    eventTime.moment = m;
    eventTime.timeZone = this.timeZone;
    if (stringShouldChange) {
      eventTime.hhMm = m.format('hh:mm');
      eventTime.amPm = m.format('A');
    }
  }

  updateEventDateText() {
    const eventDateMoment = moment(this.ngbDateToString(this.eventInfo.sessions[0].eventDate), 'YYYY-MM-DD');
    this.eventInfo.eventDateText = eventDateMoment.format('dddd, MMMM D');
    if (this.numberOfSessions > 1) {
      if (this.numberOfSessions === 2) {
        this.eventInfo.eventDateText += '\nTwo Sessions';
      }
      if (this.numberOfSessions === 3) {
        this.eventInfo.eventDateText += '\nThree Sessions';
      }
    }
    let shouldShowEventTimeWarning = false;
    for (const session of this.eventInfo.sessions) {
      // check dates for warning
      if (session.includeEventEndTime) {
        const eventEndTimeMoment = session.eventEndTime.moment.clone();
        if (session.eventStartTime.moment.isAfter(eventEndTimeMoment)) {
          eventEndTimeMoment.add(1, 'days');
        }
        const sessionLength = eventEndTimeMoment.diff(session.eventStartTime.moment, 'hours', true);
        if (Math.abs(Math.round(sessionLength)) > 12 ) {
          shouldShowEventTimeWarning = true;
        }
      }

      let eventTimeText = session.eventStartTime.moment.format('h:mm A');
      if (session.includeEventEndTime) {
        eventTimeText += ' – ' + session.eventEndTime.moment.format('h:mm A');
      }
      this.eventInfo.eventDateText += '\n' + eventTimeText;
    }
    this.shouldShowEventTimeWarning = shouldShowEventTimeWarning;
  }

  eventDateTextChanged(e) {
    const maxLines = 5;
    const lines = this.eventInfo.eventDateText.split('\n');
    if (lines.length > maxLines) {
      const newLines = lines.slice(0, maxLines);
      const newText = newLines.join('\n');
      (e.target as HTMLTextAreaElement).value = newText;
    }
  }

  /** intro functions **/
  resetIntro() {
    // console.log('resetting intro');
    let intro = this.introTemplate.replace(/<[^>]*>/g, '');
    intro = this.fillIntroPlaceholders(intro);
    this.eventInfo.intro = intro;
  }

  getIntro(): string {
    let intro;
    if (this.isIntroEditable) {
      intro = this.eventInfo.intro;
    } else {
      intro = this.introTemplate;
    }
    intro = this.fillIntroPlaceholders(intro);
    return intro;
  }

  fillIntroPlaceholders(intro): string {
    const eventTimeZone = this.eventInfo.sessions[0].eventStartTime.timeZone;
    const eventDateMoment = moment(this.ngbDateToString(this.eventInfo.sessions[0].eventDate), 'YYYY-MM-DD');
    intro = intro.replace('[event_start_time]', this.eventInfo.sessions[0].eventStartTime.moment.tz(eventTimeZone)
      .format('h:mm A'));
    intro = intro.replace('[event_date]', eventDateMoment.format('MMMM D'));
    intro = intro.replace('[event_day]', eventDateMoment.format('dddd'));
    return intro;
  }
  /** end intro functions **/

  includeEventEndTimeChange(session) {
    if (session.includeEventEndTime) {
      this.setEventEndTime(session);
    } else {
      session.eventEndTime = {
        hour: 1,
        minute: 0,
        hhMm: '01:00',
        amPm: 'AM',
        moment: moment().hour(1).minute(0)
      };
    }
    this.updateEventDateText();
  }

  setEventEndTime(session) {
    session.eventEndTime.moment = session.eventStartTime.moment.clone().add(1, 'hours');
    session.timeZone = this.timeZone;
    session.eventEndTime.hour = session.eventEndTime.moment.hour();
    session.eventEndTime.minute = session.eventEndTime.moment.minute();
    session.eventEndTime.hhMm = session.eventEndTime.moment.format('hh:mm');
    session.eventEndTime.amPm = session.eventEndTime.moment.format('A');
  }

  ngbDateToString(object: NgbDate) {
    return (object) ? object.year + '-' + object.month + '-' + object.day : '';
  }

  onRsvpPhoneChange($event) {
    // console.log('rsvp phone change');
    if (this.shouldFirePhoneChangeEvent) {
      this.setRsvpMessage();
    } else {
      this.shouldFirePhoneChangeEvent = true;
    }
  }

  onVenueTypeChange(e) {
    // this.eventInfo.venue_name = '';
    this.eventInfo.venue_address_1 = '';
    this.eventInfo.venue_address_2 = '';
    this.eventInfo.venue_city = '';
    this.eventInfo.venue_state = '';
    this.eventInfo.venue_zip = '';
    this.eventInfo.webinarUrl = '';
    this.eventInfo.additionalInformation = '';
    this.eventInfo.venue_name = (this.eventInfo.venueType === VenueType.Virtual) ? 'AN ONLINE WEBINAR' : '';
    this.virtualRsvpMethod = (this.eventInfo.venueType === VenueType.Combined) ? 'phone' : 'url';
    this.setRsvpMessage();
  }

  onVirtualRsvpMethodChange() {
    this.setRsvpMessage();
  }

  setRsvpMessage() {
    if (this.eventInfo.venueType === VenueType.Virtual) {
      switch (this.virtualRsvpMethod) {
        case 'url': {
          this.eventInfo.rsvp_message = 'RSVP by clicking on the webinar URL received via email invite.';
          break;
        }
        case 'phone': {
          this.eventInfo.rsvp_message = 'RSVP by calling ' + this.eventInfo.rsvp_phone + '.';
          break;
        }
      }
    } else {
      // this.rsvp_message = 'RSVP by calling the office at ' + this.maskPipe.transform(this.rsvp_phone, '000.000.0000');
      this.eventInfo.rsvp_message = 'RSVP by calling the office at ' + this.eventInfo.rsvp_phone + '.';
    }
  }

  onRsvpDateSelect(e) {
    if (this.rsvpByDateObj) {
      const rsvpByDateMoment = moment(this.ngbDateToString(this.rsvpByDateObj), 'YYYY-MM-DD');
      this.eventInfo.rsvpByDate = `RSVP by ${rsvpByDateMoment.format('dddd, MMMM D')}`;
    } else {
      this.eventInfo.rsvpByDate = ''
    }
  }

  onRsvpDateClear() {
    this.rsvpByDateObj = null;
    this.onRsvpDateSelect(null);
  }

  refresh() {
    this.eventInfo.intro = this.getIntro();
    this.getInvitation();
    switch (this.selectedProof) {
      case 0: // Invitation
        break;
      case 1: // Voice Shot
        this.getVoiceShot();
        break;
      case 2: // HTML Email
        // this.getHtmlEmail();
        this.emailInvitationComponent.refresh();
        break;
    }
  }

  async getInvitation() {
    this.isWaiting = true;
    this.htmlToPageflex(this.eventInfo.long_description_html);
    const softReturn = String.fromCharCode(8232);
    const data = {
      topicId: this.eventInfo.topicId,
      byoSelectedTopicId: this.eventInfo.byoSelectedTopicId,
      sessions: this.eventInfo.sessions,
      physicianId: this.eventInfo.physicianId,
      email: this.eventInfo.email,
      memberServiceState: this.eventInfo.memberServiceState,
      membershipTier: this.eventInfo.membershipTier,
      pdmEmail: this.eventInfo.pdmEmail,
      physicianStatusType: this.eventInfo.physicianStatusType,
      practiceStatus: this.eventInfo.practiceStatus,
      ptmEmail: this.eventInfo.ptmEmail,
      relationshipStatus: this.eventInfo.relationshipStatus,
      staffEmail: this.eventInfo.staffEmail,
      rsvpOnline: this.eventInfo.rsvpOnline,
      roomCapacity: this.eventInfo.roomCapacity,
      eligibleProspects: this.eventInfo.eligibleProspects,
      pastPatients: this.eventInfo.pastPatients,
      shippingAddressID: (this.shippingAddress) ? this.shippingAddress.InteropID : '',
      secondShipToAddress: this.eventInfo.secondShipToAddress,
      secondShippingAddressID: (this.eventInfo.secondShipToAddress && this.secondShippingAddress) ?
        this.secondShippingAddress.InteropID : '',
      comments: this.eventInfo.comments,
      commentsName: this.eventInfo.commentsName,
      commentsPhone: this.eventInfo.commentsPhone,
      channel: this.eventInfo.channel,
      type: this.eventInfo.type
    };
    const cobrandFilter = cobrands.filter(x => x.physicianId === this.physician['physicianId']);
    let cobrand;
    let cobrandImage;
    if (cobrandFilter.length > 0) {
      cobrand = cobrandFilter[0];
      switch (cobrand.cobrand) {
        case 'Heritage Medical Associates':
          cobrandImage = 'Heritage logo_green_flyer.pdf';
          break;
        case 'Mountain View Medical Group, P.C.':
          cobrandImage = 'MountainView_cobrand text_flyer.pdf';
          break;
        case 'Nashville Medical Group, An Affiliate of Baptist Hospital':
          cobrandImage = 'NashvilleMedicalGroup_logo_flyer.pdf';
          break;
        case 'North Shore Physicians Group':
          cobrandImage = 'NSPG Logo_4C_flyer.pdf';
          break;
        case 'Piedmont Physicians':
          cobrandImage = 'Piedmont_cobrand text_flyer.pdf';
          break;
        default:
          cobrandImage = '';
          break;
      }
    }
    let patient_guests = this.eventInfo.patient_guests;
    if (this.eventInfo.type === 'yoch') {
      patient_guests = 'Red-Box_YOCH.pdf';
    }
    // console.log(cobrandImage);
    const eventTimeZone = this.eventInfo.sessions[0].eventStartTime.timeZone;
    const variant: any = {
      ProductInteropID: this.productInteropIDs['Invitation'][this.eventInfo.type],
      Specs: {
        fullName: {
          Value: this.eventInfo.fullName
        },
        LastName: {
          Value: this.eventInfo.LastName
        },
        Specialty: {
          Value: this.eventInfo.Specialty
        },
        Address: {
          Value: this.eventInfo.Address
        },
        Address2: {
          Value: this.eventInfo.Address2
        },
        City: {
          Value: this.eventInfo.City
        },
        State: {
          Value: this.eventInfo.State
        },
        Zip: {
          Value: this.eventInfo.Zip
        },
        Phone: {
          Value: this.eventInfo.Phone
        },
        fax: {
          Value: this.eventInfo.fax
        },
        Website: {
          Value: this.eventInfo.Website
        },
        facebook: {
          Value: this.eventInfo.facebook
        },
        twitter: {
          Value: this.eventInfo.twitter
        },
        coBrandingInfo: {
          Value: cobrand
        },
        coBrandingImage: {
          Value: cobrandImage
        },
        logo: {
          Value: this.eventInfo.logo
        },
        title: {
          Value: this.eventInfo.title
        },
        intro: {
          Value: this.eventInfo.intro
        },
        header_image: {
          Value: this.eventInfo.header_image
        },
        long_description: {
          Value: this.long_description
        },
        long_description_html: {
          Value: this.eventInfo.long_description_html
        },
        brief_description: {
          Value: this.eventInfo.brief_description
        },
        speaker: {
          Value: this.eventInfo.speaker
        },
        speakervs: {
          Value: this.eventInfo.speakervs
        },
        patient_guests: {
          Value: patient_guests
        },
        event_date: {
          Value: this.ngbDateToString(this.eventInfo.sessions[0].eventDate)
        },
        event_start_time: {
          Value: this.eventInfo.sessions[0].eventStartTime.moment.tz(eventTimeZone).format('HH:mm')
        },
        event_date_text: {
          Value: this.eventInfo.eventDateText.replace(/\n/g,  softReturn)
        },
        venue_name: {
          Value: this.eventInfo.venue_name
        },
        venue_address_1: {
          Value: this.eventInfo.venue_address_1
        },
        venue_address_2: {
          Value: this.eventInfo.venue_address_2
        },
        venue_city: {
          Value: this.eventInfo.venue_city
        },
        venue_state: {
          Value: this.eventInfo.venue_state
        },
        venue_zip: {
          Value: this.eventInfo.venue_zip
        },
        rsvp_phone: {
          Value: this.eventInfo.rsvp_phone
        },
        rsvp_message: {
          Value: this.eventInfo.rsvp_message
        },
        venueType: {
          Value: (this.eventInfo.venueType)
        },
        webinarUrl: {
          Value: this.eventInfo.webinarUrl
        },
        additionalInformation: {
          Value: this.eventInfo.additionalInformation.replace(/\n/g,  softReturn)
        },
        rsvpByDate: {
          Value:  this.eventInfo.rsvpByDate
        },
        data: {
          Value: JSON.stringify(data)
        }
      }
    };
    if (this.invitationVariantId) {
      variant.InteropID = this.invitationVariantId;
    }
    const response = await this.four51Service.createVariant(variant).toPromise();
    if (!this.invitationVariantId) {
      // create order
      this.invitationVariantId = response.body.InteropID;
      this.saveOrder();
    }
    this.inviteImageUrl = response.body.PreviewUrl + '?r=' + Math.random();
    this.inviteProofUrl = response.body.ProofUrl + '&r=' + Math.random();
  }

  async getVoiceShot() {
    this.isWaiting = true;
    const eventDateMoment = moment(this.ngbDateToString(this.eventInfo.sessions[0].eventDate), 'YYYY-MM-DD');
    let eventDateText;
    let briefDescriptionText;
    if (this.numberOfSessions === 1 ) {
      eventDateText = eventDateMoment.format('dddd, MMMM D');
      briefDescriptionText = this.eventInfo.brief_description;
    } else {
      eventDateText = 'one of the sessions for our upcoming event. The first session is on ' + eventDateMoment.format('dddd, MMMM D');
      briefDescriptionText = 'For additional sessions offered, check your email invitation or call the office. At the event, ' +
        this.eventInfo.brief_description.substring(0, 1).toLowerCase() + this.eventInfo.brief_description.substring(1);
    }
    const data = {
      topicId: this.eventInfo.topicId,
      byoSelectedTopicId: this.eventInfo.byoSelectedTopicId,
      sessions: this.eventInfo.sessions,
      type: this.eventInfo.type
    };
    const eventTimeZone = this.eventInfo.sessions[0].eventStartTime.timeZone;
    const variant: any = {
      ProductInteropID: this.productInteropIDs['VoiceShot'][this.eventInfo.type],
      Specs: {
        title: {
          Value: this.eventInfo.title
        },
        LastName: {
          Value: this.eventInfo.LastName
        },
        speakervs: {
          Value: this.eventInfo.speakervs
        },
        event_start_time: {
          Value: this.eventInfo.sessions[0].eventStartTime.moment.tz(eventTimeZone).format('h:mm A')
        },
        event_date_text: {
          Value: eventDateText
        },
        venue_name: {
          Value: this.eventInfo.venue_name
        },
        venue_address_1: {
          Value: this.eventInfo.venue_address_1
        },
        venue_address_2: {
          Value: this.eventInfo.venue_address_2
        },
        venue_city: {
          Value: this.eventInfo.venue_city
        },
        brief_description: {
          Value: this.eventInfo.brief_description
        },
        brief_description_text: {
          Value: briefDescriptionText
        },
        rsvp_phone: {
          Value: this.eventInfo.rsvp_phone
        },
        rsvp_message: {
          Value: this.eventInfo.rsvp_message
        },
        venueType: {
          Value: (this.eventInfo.venueType)
        },
        data: {
          Value: JSON.stringify(data)
        }
      }
    };
    if (this.voiceShotVariantId) {
      variant.InteropID = this.voiceShotVariantId;
    }
    const response = await this.four51Service.createVariant(variant).toPromise();
    this.voiceShotVariantId = response.body.InteropID;
    this.voiceShotImageUrl = response.body.PreviewUrl + '?r=' + Math.random();
    this.voiceShotProofUrl = response.body.ProofUrl + '&r=' + Math.random();
  }

  download() {
    const w: any = window;
    const isIOS = (/iPad|iPhone|iPod/.test(navigator.platform) ||
      (navigator.platform === 'MacIntel' && navigator.maxTouchPoints > 1)) &&
      !w.MSStream;
    let downloadingMessage;
    if (isIOS) {
      downloadingMessage = 'Your file is downloading. The file will appear in a new tab on your browser.';
    } else {
      downloadingMessage = 'Your file is downloading. Depending on your browser, it may appear in your status bar below or your ' +
        'default \"Downloads\" folder.';
    }
    const modalRef = this.modalService.open(ModalComponent);
    const modal: ModalComponent = modalRef.componentInstance;
    modal.title = 'Downloading';
    modal.body = downloadingMessage;
    modal.buttons = [{label: 'OK', isNeutral: true, action: ''}];
    let url: string;
    switch (this.selectedProof) {
      case 0:
        // this.getPdf(this.inviteProofUrl, 'invitation');
        url = this.inviteProofUrl;
        break;
      case 1:
        url = this.voiceShotProofUrl;
        break;
    }
    if (isIOS) {
      window.open(url, '_blank');
    } else {
      window.location.replace(url);
    }
  }

  getPdf(url, name) {
    this.http.get<any>(url).subscribe((data: HttpResponse<any>) => {
      saveAs(new Blob([data.body], { type: 'application/pdf' }), name);
    });
  }

  saveOrder() {
    const order = {
      LineItems: [
        {
          Product: {
            InteropID: this.productInteropIDs['Invitation'][this.eventInfo.type]
          },
          Quantity: 1,
          Variant: {
            InteropID: this.invitationVariantId
          }
        }
      ],
      Type: 'Standard'
    };
    // this.four51Service.createOrUpdateOrder(order).subscribe( response => {
    //   // console.log('order draft saved');
    //   this.orderId = response.body.ID;
    //   this.four51Service.setCurrentOrder(this.orderId).subscribe(res => {
    //     // console.log('user currentorder updated');
    //   });
    // });

  }

  async submitOrder(f: NgForm) {
    if (f.valid) {
      this.submitted = true;
      this.isWholePageWaiting = true;
      // console.log('submitting order: ' + this.orderId);
      // let order: any;
      // let response: any;

      const propagoOrderLines = [
        {
          sku: 'MD.Invitation-DOWN',
          quantity: 1,
          // custom01: '',
          // custom02: '',
          // custom03: '',
          printFile: this.inviteProofUrl.replace('/PDF/', '/PDFO/'),
          assetId: this.invitationVariantId,
          productId: 'MDVIP-Invitation',
          name: 'Invitation',
        }
      ]

      const propagoOrder = {
        signatureRequired: false,
        isExpedited: false,
        promoCode: '',
        invoiceEmail: '',
        mailInvoice: false,
        username: this.physician.username,
        orderTag: '',
        comments: (!environment.production || environment.isTest) ? 'TEST-EVENT-SITE': '',
        alternativeEmail: '',
        carrier: 'FedEx - Ground',
        desiredShipDate: moment().add(1,'d').startOf('d').format('YYYY-MM-DDThh:mm:ssZ'), //'2024-12-11T05:00:00Z',
        shippingAddress: {
          contact: 'Dr. ' + this.eventInfo.LastName,
          company: this.eventInfo.fullName,
          address1: this.eventInfo.Address,
          address2: this.eventInfo.Address2,
          address3: '',
          city: this.eventInfo.City,
          state: this.eventInfo.State,
          country: 'US',
          zip: this.eventInfo.Zip,
          externalId: '',
          phone: this.eventInfo.Phone,
          email: this.physician.email,
          isResidential: false,
          isSignatureRequired: false
        },
        accountingUnit: '',
        billingAddress: {
          company: 'MDVIP',
          contact: 'Accounts Payable',
          address1: '4950 Communication Ave.',
          address2: 'Suite 100',
          city: 'Boca Raton',
          state: 'FL',
          country: 'US',
          zip: '33431',
          externalId: ''
        },
        placeOrderLines: [],
        orderField1: '', //JSON.stringify(this.eventInfo),
        orderField2: '',
        orderField3: '',
        orderField4: '',
        orderField5: this.physician.username,
        poNumber: '',
        verifyPONumber: false,
        thirdPartyAccountNumber: '',
        doNotSendNotificationEmails: null,
        // customFields: [
        //   {
        //     key: 'string',
        //     value: 'string'
        //   }
        // ]
      };

      const propagoSecondOrderLines = [];

      const secondOrder = (this.eventInfo.secondShipToAddress) ? {
        signatureRequired: false,
        isExpedited: false,
        promoCode: '',
        invoiceEmail: '',
        mailInvoice: false,
        username: this.physician.username,
        orderTag: '',
        comments: (!environment.production || environment.isTest) ? 'TEST-EVENT-SITE': '',
        alternativeEmail: '',
        carrier: 'FedEx - Ground',
        desiredShipDate:  moment().add(1,'d').startOf('d').format('YYYY-MM-DDThh:mm:ssZ'),
        shippingAddress: {
          contact: this.saFirstName + ' ' + this.saLastName,
          company: this.saCompanyName,
          address1: this.saAddress,
          address2: this.saAddress2,
          address3: '',
          city: this.saCity,
          state: this.saState,
          country: 'US',
          zip: this.saZip,
          externalId: '',
          phone: this.saPhone,
          email: this.physician.email,
        },
        accountingUnit: '',
        billingAddress: {
          company: 'MDVIP',
          contact: 'Accounts Payable',
          address1: '4950 Communication Ave.',
          address2: 'Suite 100',
          city: 'Boca Raton',
          state: 'FL',
          country: 'US',
          zip: '33431',
          externalId: ''
        },
        placeOrderLines: [],
        orderField1: '',
        orderField2: '',
        orderField3: '',
        orderField4: '',
        orderField5: this.physician.username,
        poNumber: '',
        verifyPONumber: false,
        thirdPartyAccountNumber: '',
        doNotSendNotificationEmails: null,
        // customFields: [
        //   {
        //     key: 'string',
        //     value: 'string'
        //   }
        // ]
      } : null;

      await this.getInvitation(); // in case user did not refresh and save ship address IDs

      // order.LineItems[0].ShipAddressID = downloadAddress.ID;

      // This would be a good spot to add our line items.
      // const digitalLineItems = [];
      // const primaryShipToLineItems = [];
      // const secondaryShipToLineItems = [];

      // additional invitations
      if (this.invitationQuantity > 0) {
        propagoOrderLines.push({
          sku: 'MD.Invitation',
          quantity: this.invitationQuantity,
          printFile: this.inviteProofUrl.replace('/PDF/', '/PDFO/'),
          assetId: this.invitationVariantId,
          productId: 'MDVIP-Invitation',
          name: 'Invitation',
        });
      }

      await this.getVoiceShot(); // in case user did not refresh
      if (this.voiceShotVariantId) {
        propagoOrderLines.push({
          sku: 'MD.VoiceShot',
          quantity: 1,
          printFile: this.voiceShotProofUrl.replace('/PDF/', '/PDFO/'),
          assetId: this.voiceShotVariantId,
          productId: 'MDVIP-VoiceShot',
          name: 'Voice Shot - PDF Only',
        })
      }

      const facebookVariant: any = await this.getFacebookVariant();
      propagoOrderLines.push({
        sku: 'MD.Facebook',
        quantity: 1,
        printFile: facebookVariant.ProofUrl.replace('/PDF/', '/PDFO/'),
        assetId: facebookVariant.InteropID,
        productId: 'MDVIP-Facebook',
        name: 'Facebook - PDF Only',
      });

      // const emailVariant: any = await this.getEmailVariant();
      const emailVariant: any = await this.emailInvitationService.refresh(null, this.eventInfo);
      // console.log(emailVariant);
      propagoOrderLines.push({
        sku: 'MD.InvitationEmail',
        quantity: 1,
        printFile: '',
        assetId: emailVariant.InteropID,
        productId: 'MDVIP-InvitationEmail',
        name: 'Email - HTML Only',
      });

      if (this.selectedTopic.handouts.length > 0) {
        let handoutQuantity: number;
        let additionalHandoutQuantity: number;
        if (this.selectedTopic.handout_quantities.length > 1) {
          handoutQuantity = this.handoutQuantity;
          if (this.eventInfo.secondShipToAddress && this.selectedTopic.handout_to_second_address) {
            additionalHandoutQuantity = this.additionalHandoutQuantity;
          } else {
            additionalHandoutQuantity = 0;
          }
        } else {
          handoutQuantity = this.selectedTopic.handout_quantities[0];
          if (this.eventInfo.secondShipToAddress && this.selectedTopic.handout_to_second_address) {
            additionalHandoutQuantity = this.selectedTopic.handout_quantities[0];
          } else {
            additionalHandoutQuantity = 0;
          }
        }
        if (handoutQuantity > 0) {
          for (const handout of this.selectedTopic.handouts) {
            propagoOrderLines.push({
              sku: handout.sku,
              quantity: handoutQuantity,
              printFile: '',
              assetId: null,
              productId: handout.productId,
              name: handout.name,
            });
          }
        }
        if (additionalHandoutQuantity > 0) {
          for (const handout of this.selectedTopic.handouts) {
            propagoSecondOrderLines.push({
              sku: handout.sku,
              quantity: additionalHandoutQuantity,
              printFile: '',
              assetId: null,
              productId: handout.productId,
              name: handout.name,
            });
          }
        }
      }

      if (this.additionalInvitationQuantity > 0) {
        propagoSecondOrderLines.push({
          sku: 'MD.Invitation',
          quantity: this.additionalInvitationQuantity,
          printFile: this.inviteProofUrl.replace('/PDF/', '/PDFO/'),
          assetId: this.invitationVariantId,
          productId: 'MDVIP-Invitation',
          name: 'Invitation',
        });
      }

      // Tip Sheet - PDF Only
      let tipSheetSKU: string;
      let tipSheetProductId: string;
      if (this.needsApproval) {
        tipSheetSKU = 'MD.TipSheetNA';
        tipSheetProductId = 'MDVIP-TipSheetNA';
      } else {
        tipSheetSKU = 'MD.TipSheet';
        tipSheetProductId = 'MDVIP-TipSheet';
      }
      propagoOrderLines.push({
        sku: tipSheetSKU,
        quantity: 1,
        printFile: '',
        assetId: null,
        productId: tipSheetProductId,
        name: 'Tip Sheet - PDF Only'
      });

      // Event Waiver - PDF Only
      const eventWaiverVariant: any = await this.getEventWaiverVariant();
      propagoOrderLines.push({
        sku: 'MD.EventWaiver',
        quantity: 1,
        printFile: eventWaiverVariant.ProofUrl.replace('/PDF/', '/PDFO/'),
        assetId: eventWaiverVariant.InteropID,
        productId: 'MDVIP-EventWaiver',
        name: 'Event Waiver - PDF Only',
      });

      // // order.LineItems.push(...primaryShipToLineItems, ...secondaryShipToLineItems, ...digitalLineItems);
      // const hasPhysicalShipTo = (primaryShipToLineItems.length > 0 || secondaryShipToLineItems.length > 0);
      // if (hasPhysicalShipTo) {
      //   order.LineItems.push(...primaryShipToLineItems, ...secondaryShipToLineItems);
      // } else {
      //   order.LineItems.push(...digitalLineItems);
      // }

      propagoOrder.placeOrderLines = propagoOrderLines;
      if (secondOrder) {
        secondOrder.placeOrderLines = propagoSecondOrderLines;
      }

      // // update order with ship to and bill to IDs
      // response = await this.four51Service.createOrUpdateOrder(order).toPromise();
      // order = response.body;

      // // rearrange items if necessary
      // // if (primaryShipToLineItems.length > 0 || secondaryShipToLineItems.length > 0) {
      // if (hasPhysicalShipTo) {
      //   // delete the original invitation so the physical items are first on the order
      //   const firstLineItem = order.LineItems[0];
      //   order = await this.four51Service.deleteOrder(order.ID, firstLineItem.ID).toPromise();
      //   // now add it back
      //   delete firstLineItem.ID;
      //   // order.LineItems = [firstLineItem];
      //   digitalLineItems.unshift(firstLineItem);
      //   order.LineItems = [...digitalLineItems];
      //   response = await this.four51Service.createOrUpdateOrder(order).toPromise();
      //   order = response.body;
      // }

      /*
      // get shipper
      shippers = await this.four51Service.getShippers(this.orderId).toPromise();
      console.log(shippers);
      const shipper = shippers.find( x => x.Name === 'FedEx 2Day');
      if (shipper) {
        for (const lineItem of order.LineItems) {
          lineItem.ShipperID = shipper.ID;
        }
      }
      order.isEditable = true;
      order.ShippingCost = 0;

      response = await this.four51Service.createOrUpdateOrder(order).toPromise();
      order = response.body;
      */

      // // put order for final submission
      // response = await this.four51Service.putOrder(order).toPromise();
      // order = response.body;
      // // console.log(order);
      // this.orderNumber = order.ExternalID;

      if (this.needsApproval) {
        // this.sendEmails(order.ExternalID);
        // const pendingPropagoOrder = JSON.parse(JSON.stringify(propagoOrder));
        const submitted = moment();
        const pendingOrder = {
          username: propagoOrder.username,
          status: 'Pending',
          type: 'Event',
          submitted: submitted.format('YYYY-MM-DDTHH:mm:ss') + 'Z',
          eventInfo: this.eventInfo,
          order: propagoOrder,
          secondOrder: secondOrder
        }
        const pendingResponse = await this.apiService.createPendingOrder(pendingOrder).toPromise();
        this.isWholePageWaiting = false;
        this.sendEmails(`Pending-${submitted.format('YYYY-MM-DD-HH-mm')}`);
      } else {
        const response = await this.apiService.createOrder(propagoOrder).toPromise();
        if (response && !response.error && response.results) { 
          this.orderNumber = response.results;
          if (secondOrder) {
            const secondResponse = await this.apiService.createOrder(secondOrder).toPromise();
          }
          const approvedOrder = {
            orderNumber: this.orderNumber,
            username: propagoOrder.username,
            status: 'Approved',
            type: 'Event',
            submitted: moment().format('YYYY-MM-DDTHH:mm:ss') + 'Z',
            eventInfo: this.eventInfo,
            order: propagoOrder,
            secondOrder: secondOrder
          }
          await this.apiService.createPendingOrder(approvedOrder).toPromise();
          // console.log(response);
          this.isWholePageWaiting = false;
          const emailHtml = await this.emailInvitationService.getHtml(emailVariant);
          await this.sendAutoApprovedEmails(propagoOrder, emailHtml);
          await this.createCampaign(propagoOrder, emailHtml);
        }
      }
    } // if (f.valid)
  }

  async createCampaign(propagoOrder: any, emailHtml: string) {
    // PatientType valid values are Member, Prospect, Past Patient or Both
    let PatientType = 'Member';
    if (this.eventInfo.eligibleProspects === 'yes') {
      if (this.eventInfo.pastPatients === 'yes') {
        PatientType = 'Both'; // If Invite Recent Prospects = Yes && Invite Former Patients = Yes, Type = Both
      } else {
        PatientType = 'Prospect'; // If Invite Recent Prospects = Yes && Invite Former Patients = No, Type = Prospect
      }
    } else {
      if (this.eventInfo.pastPatients === 'yes') {
        PatientType = 'Past Patient'; // If Invite Recent Prospects = No && Invite Former Patients = Yes, Type = Past Patient
      }
    }
    const eventTimeZone = this.eventInfo.sessions[0].eventStartTime.timeZone;
    const eventDateMoment = moment(this.ngbDateToString(this.eventInfo.sessions[0].eventDate), 'YYYY-MM-DD');
    const EventBeginTime = eventDateMoment.format('MM/DD/YYYY') + ' ' +
      this.eventInfo.sessions[0].eventStartTime.moment.tz(eventTimeZone).format('hh:mm A');
    let EventEndTime = '';
    if (this.eventInfo.sessions[0].includeEventEndTime) {
      EventEndTime = eventDateMoment.format('MM/DD/YYYY') + ' ' +
        this.eventInfo.sessions[0].eventEndTime.moment.tz(eventTimeZone).format('hh:mm A');
    }
    const CampaignName = this.eventInfo.fullName + ' - ' + this.eventInfo.title.substring(0, 25) + ' - ' + eventDateMoment.format('MM/DD/YYYY');

    let VenueAddress = this.eventInfo.venue_address_1;
    if (this.eventInfo.venue_address_2) {
      VenueAddress += ', ' + this.eventInfo.venue_address_2;
    }
    const data = {
      PatientType,
      AccountId: this.eventInfo.physicianId,
      EventBeginTime,
      EventEndTime,
      CampaignName,
      EmailHTML: emailHtml,
      VenueName: this.eventInfo.venue_name,
      VenueAddress,
      VenueCity: this.eventInfo.venue_city,
      VenueState: this.eventInfo.venue_state,
      VenueZipCode: this.eventInfo.venue_zip,
      VenueEventPhone: this.eventInfo.rsvp_phone.replace('.', ''),
      VenueRoomCapacity: +this.eventInfo.roomCapacity || 0
    };
    console.log(data)
    const createCampaignResponse = await this.four51Service.createCampaign(data).toPromise();
    console.log(createCampaignResponse);
  }

  async getFacebookVariant() {
    const eventTimeZone = this.eventInfo.sessions[0].eventStartTime.timeZone;
    const eventDateMoment = moment(this.ngbDateToString(this.eventInfo.sessions[0].eventDate), 'YYYY-MM-DD');
    let eventDateText;
    if (this.numberOfSessions === 1 ) {
      eventDateText = eventDateMoment.format('dddd, MMMM D') + ', ' +
        this.eventInfo.sessions[0].eventStartTime.moment.tz(eventTimeZone).format('h:mm A');
    } else {
      eventDateText = 'The first Session is on ' + eventDateMoment.format('dddd, MMMM D') + ' at ' +
        this.eventInfo.sessions[0].eventStartTime.moment.tz(eventTimeZone).format('h:mm A');
    }
    const data = {
      topicId: this.eventInfo.topicId,
      byoSelectedTopicId: this.eventInfo.byoSelectedTopicId,
      sessions: this.eventInfo.sessions,
      type: this.eventInfo.type
    };
    const variant: any = {
      ProductInteropID: this.productInteropIDs['Facebook'][this.eventInfo.type],
      Specs: {
        title: {
          Value: this.eventInfo.title
        },
        event_date_text: {
          Value: eventDateText
        },
        venue_name: {
          Value: this.eventInfo.venue_name
        },
        venue_address_1: {
          Value: this.eventInfo.venue_address_1
        },
        venue_address_2: {
          Value: this.eventInfo.venue_address_2
        },
        venue_city: {
          Value: this.eventInfo.venue_city
        },
        brief_description: {
          Value: this.eventInfo.brief_description
        },
        rsvp_phone: {
          Value: this.eventInfo.rsvp_phone
        },
        rsvp_message: {
          Value: this.eventInfo.rsvp_message
        },
        venueType: {
          Value: (this.eventInfo.venueType)
        },
        webinarUrl: {
          Value: this.eventInfo.webinarUrl
        },
        data: {
          Value: JSON.stringify(data)
        }
      }
    };
    const response = await this.four51Service.createVariant(variant).toPromise();
    return response.body;
  }

  async getEventWaiverVariant() {
    const data = {
      topicId: this.eventInfo.topicId,
      byoSelectedTopicId: this.eventInfo.byoSelectedTopicId,
      sessions: this.eventInfo.sessions
    };
    const variant: any = {
      ProductInteropID: 'MDVIP-EventWaiver',
      Specs: {
        fullName: {
          Value: this.eventInfo.fullName
        },
        data: {
          Value: JSON.stringify(data)
        }
      }
    };
    const response = await this.four51Service.createVariant(variant).toPromise();
    return response.body;
  }

  onPreviewLoaded() {
    this.isWaiting = false;
  }

  onPreviewError(e) {
    console.log(e);
    this.isWaiting = false;
  }

  onProofSelected(index) {
    if (index !== this.selectedProof) {
      this.selectedProof = index;
      this.changeDectector.detectChanges();
      this.refresh();
    }
  }

  onEventInfoFormSubmit(f) {
    if (f.valid) {
      this.router.navigate(['/events', {step: 3}]);
    } else {
      this.validationErrors = Object.keys(f.controls)
        .filter(fieldName => f.controls[fieldName].errors)
        .map(fieldName => {
          fieldName = fieldName.replace( /([A-Z])/g, ' $1' );
          return fieldName.charAt(0).toUpperCase() + fieldName.slice(1);
        });
      let body = '<p>There is incomplete or invalid data on the form. </p><ul>';
      for (const validationError of this.validationErrors) {
        body += '<li>' + validationError + '</li>';
      }
      const modalRef = this.modalService.open(ModalComponent);
      const modal: ModalComponent = modalRef.componentInstance;
      modal.title = 'Invalid Data';
      modal.body = body;
      modal.buttons = [{label: 'OK', isNeutral: true, action: ''}];
    }
  }

  async sendEmails(orderNumber: string) {
    try {
      const formattedEventDate = moment(this.ngbDateToString(this.eventInfo.sessions[0].eventDate), 'YYYY-MM-DD').format('MM/DD/YYYY');

      /*
       * Event Submitted Internal Email
       */
      const eventSubmittedInternalEmail: MailerMessage = new MailerMessage();
      eventSubmittedInternalEmail.Subject = this.eventInfo.fullName + ' , has submitted an event order';
      eventSubmittedInternalEmail.Bcc = environment.emailBcc;
      if (!environment.production || environment.isTest) {
        eventSubmittedInternalEmail.To = environment.emailTo;
        eventSubmittedInternalEmail.Subject = 'TESTING: ' + eventSubmittedInternalEmail.Subject;
      } else {
        eventSubmittedInternalEmail.To = ['Marketing Support Site <MarketingSupportSite@mdvip.com>'];
      }
      eventSubmittedInternalEmail.From = 'Marketing Support Site <MarketingSupportSite@mdvip.com>';
      eventSubmittedInternalEmail.Body = 'This message requires an email client that supports HTML email.';
      const approval_url = window.location.origin + '/approval';
      let alt_email = '';
      if (this.eventInfo.ptmEmail !== '') {
        alt_email += this.eventInfo.ptmEmail + '<br/>';
      }
      if (this.eventInfo.relationshipStatus === 'MDVIP Employed Affiliate') {
        alt_email += 'OwnedPracticeOps@mdvip.com<br/>';
      }
      if (this.eventInfo.physicianStatusType.toString().toLowerCase() === 'separation in progress') {
        alt_email += 'SuccessionEvents@mdvip.com<br/>';
      }

      let saAddress = '';
      if (this.eventInfo.secondShipToAddress) {
        saAddress = this.saAddress;
        if (this.saAddress2 && this.saAddress2.length > 0) {
          saAddress += '<br />' + this.saAddress2;
        }
      }
      const internalMailTemplateValues: MailTemplateValue[] = [
        {name: 'approval_url', value: approval_url},
        {name: 'date_submitted', value: moment().format('MM/DD/YYYY h:mm A')},
        {name: 'title', value: this.eventInfo.title},
        {name: 'event_date', value: formattedEventDate},
        {name: 'fullname', value: this.eventInfo.fullName},
        {name: 'pdm_email', value: this.eventInfo.pdmEmail},
        {name: 'ptm_email', value: alt_email},
        {name: 'room_capacity', value: this.eventInfo.roomCapacity },
        {name: 'rsvp_online', value: (this.eventInfo.rsvpOnline) ? 'Yes' : 'No'},
        {name: 'channel', value: this.eventInfo.channel},
        {name: 'practice_status', value: this.eventInfo.practiceStatus},
        {name: 'relationship_status', value: this.eventInfo.relationshipStatus},
        {name: 'physician_status_type', value: this.eventInfo.physicianStatusType},
        {name: 'eligible_prospects', value: this.yesNoOrNull(this.eventInfo.eligibleProspects)},
        {name: 'past_patients', value: this.yesNoOrNull(this.eventInfo.pastPatients)},
        {name: 'ship_to_second_address', value: (this.eventInfo.secondShipToAddress) ? 'Yes' : 'No'},
        {name: 'display_second_address', value: (this.eventInfo.secondShipToAddress) ? 'table-row' : 'none' },
        {name: 'sa_attention', value: this.saFirstName + ' ' + this.saLastName },
        {name: 'sa_address', value: saAddress },
        {name: 'sa_city', value: this.saCity },
        {name: 'sa_state', value: this.saState },
        {name: 'sa_zip', value: this.saZip },
        {name: 'comment', value: this.eventInfo.comments},
        {name: 'comment_name', value: this.eventInfo.commentsName},
        {name: 'comment_phone', value: this.eventInfo.commentsPhone},
      ];
      eventSubmittedInternalEmail.Html = await this.mailTemplateService
        .replaceValues('./assets/html/eventSubmittedInternal.html', internalMailTemplateValues);
      this.mailerService.sendMessage(eventSubmittedInternalEmail);

      /*
       * Event Submitted Doctor Email
       */
      const eventSubmittedDoctorEmail: MailerMessage = new MailerMessage();
      eventSubmittedDoctorEmail.Subject = this.eventInfo.fullName + ', Event Order ' + orderNumber + ' has been received';
      eventSubmittedInternalEmail.Bcc = environment.emailBcc;
      if (!environment.production || environment.isTest) {
        eventSubmittedDoctorEmail.To = environment.emailTo;
        eventSubmittedDoctorEmail.Subject = 'TESTING: ' + eventSubmittedDoctorEmail.Subject;
      } else {
        this.physicianEmailAddrService.setAddresses(eventSubmittedDoctorEmail, this.eventInfo.email, this.eventInfo.staffEmail,
          this.eventInfo.ptmEmail, this.eventInfo.pdmEmail, this.eventInfo.practiceStatus, this.eventInfo.relationshipStatus,
          this.eventInfo.physicianStatusType);
      }
      eventSubmittedDoctorEmail.From = 'Marketing Support Site <MarketingSupportSite@mdvip.com>';
      eventSubmittedDoctorEmail.Body = 'This message requires an email client that supports HTML email.';
      let items = '';
      const itemOpen = '<tr><td valign="middle" ' +
        'style="width:0.1%; padding: 0 18px 0 0; font-size: 32px; mso-line-height-rule:exactly;  mso-text-raise: -8px; line-height: 16px;' +
        'font-family: Helvetica, Arial, sans-serif; ' +
        'color: #666666;">&bull;</td><td valign="top" ' +
        'style="font-size: 16px; line-height: 25px; font-family: Helvetica, Arial, sans-serif; color: #666666;">';
      const itemClose = '</td></tr>';
      if (this.invitationQuantity > 0) {
        items += itemOpen + this.invitationQuantity + ' Printed Event Invitations' + itemClose;
      }
      /*if (this.attendanceCards > 0) {
        items += itemOpen + this.attendanceCards + ' Attendance Card Kit' + itemClose;
      }*/
      if (this.handoutQuantity > 0) {
        items += itemOpen + this.handoutQuantity + ' Handouts' + itemClose;
      }
      // const historyUrl = window.location.origin + '/history';
      const historyUrl = environment.mdvipConnectUrl;
      const mailTemplateValues: MailTemplateValue[] = [
        {name: 'title', value: this.eventInfo.title},
        {name: 'order_number', value: orderNumber},
        {name: 'event_date', value: formattedEventDate},
        {name: 'items', value: items},
        {name: 'history_url', value: historyUrl}
      ];
      eventSubmittedDoctorEmail.Html = await this.mailTemplateService
        .replaceValues('./assets/html/eventSubmittedDoctor.html', mailTemplateValues);
      this.mailerService.sendMessageWithLogo(eventSubmittedDoctorEmail);

      this.step = 4;
    } catch (error) {
      const errorEmail: MailerMessage = new MailerMessage();
      errorEmail.To = ['tom@artoftechnology.com'];
      errorEmail.From = 'MarketingSupportSite@mdvip.com';
      errorEmail.Subject = 'Event Approval Email Error';
      errorEmail.Body = error.message;
      errorEmail.Html = '<div>' + error.message + '</div>';
      this.mailerService.sendMessage(errorEmail);
      const modalRef = this.modalService.open(ModalComponent);
      modalRef.componentInstance.title = 'Error Sending Email';
      modalRef.componentInstance.body = 'The order was placed, but there was an error sending the confirmation email. Please try again.';
      const cancelButton: Button = {
        label: 'Cancel',
        action: '',
        isNeutral: true
      };
      const okButton: Button = {
        label: 'Send Emails',
        action: '',
        isNeutral: false
      };
      modalRef.componentInstance.buttons = [cancelButton, okButton];
      modalRef.result
        .then(async result => {
          // ok pressed
          this.sendEmails(orderNumber);
        })
        .catch(reason => {
          // cancelled
          this.step = 4;
        });
    } finally {
      this.isWholePageWaiting = false;
    }
  }

  async sendAutoApprovedEmails(propagoOrder: any, htmlEmail: string) {
    // TODO refactor this -- largely duplicate code with event-approval
    try {
      const eventInfo = this.eventInfo; // replaced this.masterEventInfo
      const orderNumber = this.orderNumber; // replaced this.selectedOrder.ExternalID
      const topic = this.selectedTopic; // replaced this.topic
      const emailDr = true; // replaced this.emailDr
      const emailStaff = true; // replaced this.emailStaff
      const emailPtm = true; // replaced this.emailPtm
      const emailPdm = true; // replaced this.emailPdm
      const invitationLineItem = propagoOrder.placeOrderLines
        .find((x: any) => x.sku === 'MD.Invitation-DOWN');

      /*
       * Event Approved Doctor Email
       */
      const eventApprovedDoctorEmail: MailerMessage = new MailerMessage();
      const eventDateMoment = moment(this.ngbDateToString(eventInfo.sessions[0].eventDate), 'YYYY-MM-DD');
      eventApprovedDoctorEmail.Subject = eventInfo.fullName + ' Event Order ' + this.orderNumber +
        ' has been approved (Auto-Approved Event)';
      eventApprovedDoctorEmail.Bcc = environment.emailBcc;
      if (!environment.production || environment.isTest) {
        eventApprovedDoctorEmail.To = environment.emailTo;
        eventApprovedDoctorEmail.Subject = 'TESTING: ' + eventApprovedDoctorEmail.Subject;
      } else {
        this.physicianEmailAddrService.setAddresses(
          eventApprovedDoctorEmail,
          (emailDr) ? eventInfo.email : null,
          (emailStaff) ? eventInfo.staffEmail : null,
          (emailPtm) ? eventInfo.ptmEmail : null,
          (emailPdm) ? eventInfo.pdmEmail : null,
          eventInfo.practiceStatus,
          eventInfo.relationshipStatus,
          eventInfo.physicianStatusType
        );
      }
      /*if (this.emailOther && this.otherEmails.trim().length > 0) {
        const otherEmails = this.otherEmails.split(';');
        for (const otherEmail of otherEmails) {
          eventApprovedDoctorEmail.Bcc.push(otherEmail);
        }
      }*/
      if (eventApprovedDoctorEmail.To.length === 0) {
        eventApprovedDoctorEmail.To.push('Marketing Support Site <MarketingSupportSite@mdvip.com>');
      }
      eventApprovedDoctorEmail.From = 'Marketing Support Site <MarketingSupportSite@mdvip.com>';
      eventApprovedDoctorEmail.Body = 'This message requires an email client that supports HTML email.';
      let powerPointRowDisplay = 'none';
      let powerPointUrl = '';
      if (topic) {
        if (topic.powerpointfile && topic.powerpointfile.length > 0) {
          powerPointRowDisplay = 'table-row';
          if (topic.powerpointfile.length > 1 ) {
            powerPointUrl = 'these PowerPoint Presentation links ';
            for (let i = 0; i < topic.powerpointfile.length; i++) {
              const file = window.location.origin + '/assets/pptx/' + topic.powerpointfile[i] + '.pptx';
              powerPointUrl += '<a href="' + file + '"><span style="color:#DE6400;text-decoration:none">File ' + (i + 1) + '</span></a> ';
            }
          } else {
            const file = window.location.origin + '/assets/pptx/' + topic.powerpointfile[0] + '.pptx';
            powerPointUrl = 'this <a href="' + file +
              '"><span style="color:#DE6400;text-decoration:none">PowerPoint Presentation link</span></a>';
          }
        }
      }
      // const historyUrl = window.location.origin + '/history';
      const historyUrl = environment.mdvipConnectUrl;
      const eventApprovedDoctorValues: MailTemplateValue[] = [
        {name: 'order_number', value: orderNumber},
        {name: 'title', value: eventInfo.title},
        {name: 'event_date', value: eventDateMoment.format('dddd, MMMM D')},
        {name: 'invitation_url', value: invitationLineItem.printFile},
        {name: 'powerpoint_url', value: powerPointUrl},
        {name: 'powerpoint_row_display', value: powerPointRowDisplay},
        {name: 'history_url', value: historyUrl}
      ];

      // attachments Voice Shot - PDF Only, Facebook - PDF Only, MDVIP_TipSheet, Event Waiver - PDF Only
      /*
      const propagoOrderLines = [
        {
          sku: 'MD.Invitation',
          quantity: 1,
          // custom01: '',
          // custom02: '',
          // custom03: '',
          printFile: this.inviteProofUrl.replace('/PDF/', '/PDFO/'),
          assetId: this.invitationVariantId
        }
      ]
      */

      const voiceShotLineItem = propagoOrder.placeOrderLines
        .find(x => x.sku === 'MD.VoiceShot');
      const voiceShotURL = environment.apiBaseUrl +
        (new URL(voiceShotLineItem.printFile))
          .pathname.substring(1);
      const voiceShotName = 'Voice Shot - PDF Only'  + '.pdf';

      const facebookLineItem = propagoOrder.placeOrderLines
        .find(x => x.sku === 'MD.Facebook');
      const facebookURL = environment.apiBaseUrl +
        (new URL(facebookLineItem.printFile))
          .pathname.substring(1);
      const facebookName = 'Facebook - PDF Only' + '.pdf';

      const eventWaiverLineItem = propagoOrder.placeOrderLines
        .find(x => (x.sku === 'MD.EventWaiver'));
      const eventWaiverURL = environment.apiBaseUrl +
        (new URL(eventWaiverLineItem.printFile))
          .pathname.substring(1);
      const eventWaiverName = 'Event Waiver - PDF Only' + '.pdf';

      const tipSheetURL = './assets/pdf/MDVIP_TipSheet.pdf';
      const tipSheetName = 'MDVIP_TipSheet.pdf';
      /*const tipSheetAbsoluteURL = window.location.protocol + '//' + window.location.hostname + '/assets/pdf/MDVIP_TipSheet.pdf';
      // const tipSheetAbsoluteURL = 'https://mdvipsf.seprint.com/assets/pdf/MDVIP_TipSheet.pdf';

      eventApprovedDoctorValues.push({name: 'voiceshot_url', value: voiceShotURL});
      eventApprovedDoctorValues.push({name: 'facebook_url', value: facebookURL});
      eventApprovedDoctorValues.push({name: 'tipsheet_url', value: tipSheetAbsoluteURL});
      eventApprovedDoctorValues.push({name: 'eventwaiver_url', value: eventWaiverURL});*/
      eventApprovedDoctorEmail.Html = await this.mailTemplateService
        .replaceValues('./assets/html/eventApprovedDoctor.html', eventApprovedDoctorValues);

      let voiceShotData;
      let facebookData;
      let eventWaiverData;
      let tipSheetData;

      await Promise.all([
        this.getBase64Data(voiceShotURL).then(data => { voiceShotData = data; }),
        this.getBase64Data(facebookURL).then(data => { facebookData = data; }),
        this.getBase64Data(eventWaiverURL).then(data => { eventWaiverData = data; }),
        this.getBase64Data(tipSheetURL).then(data => { tipSheetData = data; })
      ]);
      // console.log('all done');

      const doctorAttachments = [
        {
          base64Data: voiceShotData,
          contentType: 'application/pdf',
          filename: voiceShotName
        },
        {
          base64Data: facebookData,
          contentType: 'application/pdf',
          filename: facebookName
        },
        {
          base64Data: eventWaiverData,
          contentType: 'application/pdf',
          filename: eventWaiverName
        },
        {
          base64Data: tipSheetData,
          contentType: 'application/pdf',
          filename: tipSheetName
        },
      ];
      // console.log(doctorAttachments);

      this.mailerService.sendMessageWithLogoAndAttachments(eventApprovedDoctorEmail, doctorAttachments);
      // this.mailerService.sendMessageWithLogo(eventApprovedDoctorEmail);

      /*
       * Event Approved Internal Email
       */
      const eventApprovedInternalEmail: MailerMessage = new MailerMessage();
      eventApprovedInternalEmail.Subject = eventInfo.fullName + ' / Marketing Support HTML Email File';
      eventApprovedInternalEmail.Bcc = environment.emailBcc;
      if (!environment.production || environment.isTest) {
        eventApprovedInternalEmail.To = environment.emailTo;
        eventApprovedInternalEmail.Subject = 'TESTING: ' + eventApprovedInternalEmail.Subject;
      } else {
        eventApprovedInternalEmail.To = ['Marketing Support Site <MarketingSupportSite@mdvip.com>'];
      }
      eventApprovedInternalEmail.From = 'Marketing Support Site <MarketingSupportSite@mdvip.com>';
      eventApprovedInternalEmail.Body = 'This message requires an email client that supports HTML email.';
      let saAttention = '';
      let saAddress = '';
      let saCity = '';
      let saState = '';
      let saZip = '';
      if (eventInfo.secondShipToAddress) {
        // saAttention = this.secondaryShipTo.FirstName + ' ' + this.secondaryShipTo.LastName;
        saAttention = this.saFirstName + ' ' + this.saLastName;
        // saAddress = this.secondaryShipTo.Street1;
        // if (this.secondaryShipTo.Street2 && this.secondaryShipTo.Street2.length > 0) {
        //  saAddress += '<br />' + this.secondaryShipTo.Street2;
        // }
        saAddress = this.saAddress;
        if (this.saAddress2 && this.saAddress2.length > 0) {
          saAddress += '<br />' + this.saAddress2;
        }
        // saCity = this.secondaryShipTo.City;
        saCity = this.saCity ;
        // saState = this.secondaryShipTo.State;
        saState = this.saState;
        // saZip = this.secondaryShipTo.Zip;
        saZip = this.saZip;
      }
      const eventApprovedInternalValues: MailTemplateValue[] = [
        {name: 'date_approved', value:  moment().format('MM/DD/YYYY h:mm A')},
        {name: 'title', value: eventInfo.title },
        {name: 'event_date', value:  eventDateMoment.format('dddd, MMMM D') },
        {name: 'fullname', value: eventInfo.fullName },
        {name: 'pdm_email', value: (eventInfo.pdmEmail) ? eventInfo.pdmEmail : '' },
        {name: 'ptm_email', value: (eventInfo.ptmEmail) ? eventInfo.ptmEmail : '' },
        {name: 'room_capacity', value: (eventInfo.roomCapacity) ? eventInfo.roomCapacity : '' },
        {name: 'rsvp_online', value: (eventInfo.rsvpOnline) ? 'Yes' : 'No' },
        {name: 'channel', value: eventInfo.channel },
        {name: 'practice_status', value: eventInfo.practiceStatus },
        {name: 'relationship_status', value: eventInfo.relationshipStatus },
        {name: 'physician_status_type', value: eventInfo.physicianStatusType },
        {name: 'eligible_prospects', value: this.yesNoOrNull(eventInfo.eligibleProspects) },
        {name: 'past_patients', value: this.yesNoOrNull(eventInfo.pastPatients) },
        {name: 'ship_to_second_address', value: (eventInfo.secondShipToAddress) ? 'Yes' : 'No' },
        {name: 'display_second_address', value: (eventInfo.secondShipToAddress) ? 'table-row' : 'none' },
        {name: 'sa_attention', value: saAttention },
        {name: 'sa_address', value: saAddress },
        {name: 'sa_city', value: saCity },
        {name: 'sa_state', value: saState },
        {name: 'sa_zip', value: saZip },
        {name: 'comment', value: (eventInfo.comments) ? eventInfo.comments : '' },
        {name: 'comment_name', value: (eventInfo.commentsName) ? eventInfo.commentsName : '' },
        {name: 'comment_phone', value: (eventInfo.commentsPhone) ? eventInfo.commentsName : '' },
      ];

      eventApprovedInternalEmail.Html = await this.mailTemplateService
        .replaceValues('./assets/html/eventApprovedInternal.html', eventApprovedInternalValues);


      // get HTML for attachment
      const attachments: any[] = [];
      if (htmlEmail) {
        // const emailLineItem = emailLineItems[0];
       
        const base64Data = btoa(unescape(encodeURIComponent(htmlEmail)));
        // const base64Data = btoa(html.replace(/[\u00A0-\u2666]/g, function(c) {
        //  return '&#' + c.charCodeAt(0) + ';';
        // }));
        // const base64Data = btoa('<html><head><title>Hello World</title></head><body><div>Hello World</div></body></html>');
        const attachment = {
          base64Data: base64Data,
          contentType: 'text/html',
          filename: 'email-invitation.html'
        };
        attachments.push(attachment);
      }
      this.mailerService.sendRawMessage(eventApprovedInternalEmail, attachments);

      this.step = 4;
    } catch (error) {
      // console.log(error)
      const errorEmail: MailerMessage = new MailerMessage();
      errorEmail.To = ['tom@artoftechnology.com'];
      errorEmail.From = 'MarketingSupportSite@mdvip.com';
      errorEmail.Subject = 'Event Approval Email Error';
      errorEmail.Body = error.message;
      errorEmail.Html = '<div>' + error.message + '</div>';
      this.mailerService.sendMessage(errorEmail);
      const modalRef = this.modalService.open(ModalComponent);
      modalRef.componentInstance.title = 'Error Sending Email';
      modalRef.componentInstance.body = 'The order was placed, but there was an error sending the confirmation email. Please try again.';
      const cancelButton: Button = {
        label: 'Cancel',
        action: '',
        isNeutral: true
      };
      const okButton: Button = {
        label: 'Send Emails',
        action: '',
        isNeutral: false
      };
      modalRef.componentInstance.buttons = [cancelButton, okButton];
      modalRef.result
        .then(async result => {
          // ok pressed
          this.sendAutoApprovedEmails(propagoOrder, htmlEmail);
        })
        .catch(reason => {
          // cancelled
          this.step = 4;
        });
    } finally {
      this.isWholePageWaiting = false;
    }
  }

  /*escapeToHtmlEntities(str: string): string {
    const escape = document.createElement('textarea');
    escape.textContent = str;
    return escape.innerHTML;
  }*/

  yesNoOrNull(str: string): string {
    if (str) {
      switch (str.toLowerCase()) {
        case 'yes': {
          return 'Yes';
        }
        case 'no': {
          return 'No';
        }
      }
    }
    return 'NULL';
  }

  async getBase64Data(url) {
    const blob = await this.http.get(url, {responseType: 'blob'}).toPromise();
    const fileReader = new FileReader();
    return new Promise((resolve, reject) => {
      fileReader.onerror = () => {
        fileReader.abort();
        reject(new DOMException('Problem parsing input file.'));
      };
      fileReader.onload = () => {
        let base64Data = fileReader.result.toString();
        base64Data = base64Data.replace('data:application/pdf;base64,', '');
        resolve(base64Data);
      };
      fileReader.readAsDataURL(blob);
    });
  }
}
