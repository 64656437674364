import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { CookieService } from 'ngx-cookie-service';
import { AppRoutingModule } from './app-routing.module';
import { QuillModule } from 'ngx-quill';
import {MaskPipe, NgxMaskModule} from 'ngx-mask';
// import * as Quill from 'quill';
// import { CKEditorModule } from '@ckeditor/ckeditor5-angular';

import {AppComponent} from './app.component';
import {Four51Service} from './four51.service';
import {HomeComponent} from './home/home.component';
import {LoginComponent} from './login/login.component';
import {EventsComponent} from './events/events.component';
import {EventInfoComponent} from './events/event-info-component';
import {PopoverComponent} from './events/popover.component';
import {EventApprovalComponent} from './events/event-approval.component';
import {InvitationComponent} from './events/assets/invitation.component';
import {EmailInvitationComponent} from './events/assets/email-invitation.component';
import {FacebookComponent} from './events/assets/facebook.component';
import {VoiceshotComponent} from './events/assets/voiceshot.component';
import {ShipToComponent} from './events/ship-to.component';
import {AssetProofComponent} from './events/asset-proof.component';

import { PdfViewerModule } from 'ng2-pdf-viewer';
import {NoSanitizePipe} from './no-sanitize-pipe';
import {HistoryComponent} from './history/history.component';
import {EventWaiverComponent} from './events/assets/event-waiver.component';
import {ModalComponent} from './modal.component';
import {PdfModalComponent} from './pdf-modal.component';
import {CartComponent} from './cart/cart.component';
import { CustomFormsModule } from 'ngx-custom-validators';
import {ProductModalComponent} from './product-modal.component';
import {SpecComponent} from './spec.component';
import {PowerpointLibraryComponent} from './powerpoint-library/powerpoint-library.component';
import {BannersComponent} from './banners/banners.component';
import {MembershipGiveawayComponent} from './membership-giveaway/membership-giveaway.component';
import {SpecFieldComponent} from './spec-field.component';
import {ShipAddressComponent} from './ship-address.component';
import {ProductEditModalComponent} from './product-edit-modal.component';
import {MembershipGiveawayEditComponent} from './events/membership-giveaway-edit.component';
import {OrderDetailModalComponent} from './history/order-detail-modal.component';
import {CrmReportComponent} from './crm-report/crm-report.component';
import {DateRangeComponent} from './date-range-component';
import {CartReactiveComponent} from './cart/cart.reactive.component';
import {CurrencyPipe} from '@angular/common';
import {EventSiteComponent} from './event-site/event-site.component';
import {MenuComponent} from './home/menu.component';
import { LookupComponent } from './history/lookup.component';


@NgModule({
  declarations: [
    AppComponent,
    EventSiteComponent,
    HomeComponent,
    LoginComponent,
    EventsComponent,
    HistoryComponent,
    EventInfoComponent,
    PopoverComponent,
    EventApprovalComponent,
    InvitationComponent,
    EmailInvitationComponent,
    FacebookComponent,
    VoiceshotComponent,
    EventWaiverComponent,
    AssetProofComponent,
    ShipToComponent,
    ModalComponent,
    NoSanitizePipe,
    PdfModalComponent,
    CartComponent,
    ProductModalComponent,
    ProductEditModalComponent,
    SpecComponent,
    SpecFieldComponent,
    PowerpointLibraryComponent,
    BannersComponent,
    MembershipGiveawayComponent,
    ShipAddressComponent,
    MembershipGiveawayEditComponent,
    OrderDetailModalComponent,
    CrmReportComponent,
    DateRangeComponent,
    CartReactiveComponent,
    MenuComponent,
    LookupComponent
  ],
  imports: [
    BrowserModule,
    HttpClientModule,
    NgbModule,
    AppRoutingModule,
    PdfViewerModule,
    FormsModule,
    QuillModule.forRoot(),
    // CKEditorModule
    NgxMaskModule.forRoot(),
    ReactiveFormsModule,
    CustomFormsModule
  ],
  entryComponents: [
    InvitationComponent,
    EmailInvitationComponent,
    FacebookComponent,
    VoiceshotComponent,
    EventWaiverComponent,
    AssetProofComponent,
    ModalComponent,
    ShipToComponent,
    PdfModalComponent,
    ProductModalComponent,
    ProductEditModalComponent,
    MembershipGiveawayEditComponent,
    OrderDetailModalComponent
  ],
  providers: [Four51Service, CookieService, MaskPipe, CurrencyPipe],
  bootstrap: [AppComponent]
})
export class AppModule { }
