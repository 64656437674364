import {Component, OnDestroy, OnInit} from '@angular/core';
import {CartService} from '../cart.service';
import {Subscription} from 'rxjs';
import {FormArray, FormBuilder, FormGroup, Validators} from '@angular/forms';

@Component({
  selector: 'app-cart-reactive',
  templateUrl: './cart.reactive.component.html',
  styleUrls: ['./cart.reactive.component.scss']
})

export class CartReactiveComponent implements OnInit, OnDestroy {
  private subscription: Subscription;
  public cart: any;
  private cartForm: FormGroup;
  constructor(private cartService: CartService, private formBuilder: FormBuilder) {}

  ngOnInit(): void {
    this.subscription = new Subscription();
    const cartSubscription = this.cartService.cartObservable().subscribe(cart => {
      this.cart = cart;
      if (cart) {
        this.buildForm();
      }
    });
    this.subscription.add(cartSubscription);
  }
  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  buildForm() {
    const quantities: FormArray = this.formBuilder.array([]);
    for (const lineItem of this.cart.placeOrderLines) {
      quantities.push(
        this.formBuilder.group({
          quantity: [lineItem.quantity, [Validators.required, Validators.min(1)]]
        })
      );
    }
    this.cartForm = this.formBuilder.group({
      quantities: quantities
    });
  }

  get f() {
    return this.cartForm.controls;
  }

  get quantities(): FormArray {
    return this.cartForm.get('quantities') as FormArray;
  }

  selectLineItem(lineItem: any) {

  }

  async removeLineItem(lineItem: any) {
    this.cartService.removeFromCart(lineItem.ID);
  }

  updateQuantities() {
    if (this.cartForm.valid) {
      // do something
    }
  }

}


