import {Component, OnInit, ChangeDetectorRef, ViewChild, OnDestroy} from '@angular/core';
import {Four51Service} from '../four51.service';
import {CookieService} from 'ngx-cookie-service';
import {Subscription} from 'rxjs';
import {MdvipUserCookie} from '../models/mdvip-user-cookie';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {Router} from '@angular/router';
import {OrderListItem} from '../models/order-list-item';
import * as moment from 'moment';
import {ModalComponent} from '../modal.component';
import {MailerMessage, MailerService} from '../mailer.service';
import {MailTemplateValue} from '../mail-template.service';
import {OrderDetailModalComponent} from './order-detail-modal.component';
import {isBoolean} from 'util';
import {isMoment} from 'moment';
import {MdvipUser} from '../models/mdvip-user';
import { ApiService } from '../api.service';

@Component({
  selector: 'app-history',
  templateUrl: './history.component.html',
  styleUrls: ['./history.component.scss']
})

export class HistoryComponent implements OnInit {
  // private subscription: Subscription;
  private pageSize = 5;
  private unsubmittedOrders: OrderListItem[] = [];
  private unsubmittedPage = 1;
  private awaitingApprovalOrders: OrderListItem[] = [];
  private awaitingApprovalPage = 1;
  private approvedOrders: OrderListItem[] = [];
  private approvedPage = 1;
  private declinedOrders: OrderListItem [] = [];
  private declinedPage = 1;
  private collateralOrders: OrderListItem [] = [];
  private collateralPage = 1;
  private collateralPageSize = 10;
  private canceledOrders: OrderListItem[] = [];
  private canceledPage = 1;
  public orderType = 'event';
  private membershipGiveawayProductId = 'A678D8E2-AD56-4B10-BB5B-08142796E227';
  private mdvipUser: MdvipUser;

  constructor(private four51Service: Four51Service,
              private changeDetector: ChangeDetectorRef, private modalService: NgbModal,
              private router: Router, private mailerService: MailerService,
              private apiService: ApiService) {}
  @ViewChild('copyModal', { static: true }) private copyModal;
  async ngOnInit() {

    // const statuses = ['Unsubmitted', 'AwaitingApproval' , 'Open', 'Completed', 'Declined', 'Canceled'];
    // const orderStats: any = await this.four51Service.getOrderStats().toPromise();
    // console.log(orderStats);
    // for (const orderStat of orderStats) {
    //   if (orderStat.Count > 0 && statuses.includes(orderStat.Status)) {
    //     this.getOrders(orderStat.Status, orderStat.Count);
    //   }
    // }
    await this.getOrders()
  }

  setOrderType(type: string) {
    this.orderType = type;
  }
  async getOrders() {
    const orders = await this.apiService.searchOrders(moment().subtract(1, 'year').startOf('day'), moment().endOf('day')).toPromise();
    const ordersToAdd = orders.map((order: any) => {
      let type = 'Collateral';
      let topicTitle = 'Collateral';

      console.log(order.orderLines);
      if (order.orderLines.find((x: any) => x.part.displaySku.toUpperCase() === 'MD.Invitation-DOWN'.toUpperCase())) {
        type = 'Event';
        topicTitle = 'Event';
      }
      if (order.orderLines.find((x: any) => x.part.displaySku.toUpperCase() === 'MD.52424'.toUpperCase())) {
        type = 'Event';
        topicTitle = 'Membership Giveaway';
      }
      
      return {
        ExternalID: order.orderDisplayNumber,
        StatusText: order.status,
        DateCreated: moment(order.orderDate),
        DateSubmittedForApproval: moment(order.orderDate),
        FromUserFirstName: order.shippingAddress.contact,
        FromUserLastName: '',
        EventDate: moment(),
        DoctorFullName: order.shippingAddress.company,
        TopicTitle: topicTitle,
        pdmEmail: '',
        shipTo: order.shippingAddress.address1,
        AutoApproved: true,
        isLoading: false,
        type
      }
    });
    this.approvedOrders = ordersToAdd.filter((x: any) => x.type === 'Event');
    this.collateralOrders = ordersToAdd.filter((x: any) => x.type === 'Collateral');
  }

  async getOrderDetails(collection: string, ordersToAdd: OrderListItem[]) {
    for (const orderListItem of ordersToAdd) {
      // const response: any = await this.four51Service.getOrderById(orderListItem.ID).toPromise();
      // const invitationLineItem = response.LineItems
      //   .find(x => (x.Product.InteropID === 'MDVIP-Invitation'
      //     || x.Product.InteropID === 'MDVIP-InvitationYOCH'));
      // if (invitationLineItem) {
      //   let specs;
      //   if (invitationLineItem.Variant.Specs) {
      //     specs = invitationLineItem.Variant.Specs;
      //   } else {
      //     specs = invitationLineItem.Specs;
      //   }
      //   const dataText = (specs.data) ? specs.data.Value : '{}';
      //   const data = JSON.parse(dataText);
      //   orderListItem.pdmEmail = (data.pdmEmail) || 'N/A';
      //   orderListItem.TopicTitle = specs.title.Value || ' ';
      //   orderListItem.DoctorFullName = specs.fullName.Value || ' ';
      //   if (specs.event_date.Value.length > 0) {
      //     orderListItem.EventDate = moment(specs.event_date.Value, 'YYYY-M-D');
      //   }
      //   orderListItem.AutoApproved = (response.Approvals.length === 0);
      //   this[collection].push(orderListItem);
      // } else {
      //   const membershipGiveawayLineItem = response.LineItems
      //     .find(x => (x.Product.InteropID === this.membershipGiveawayProductId));
      //   if (membershipGiveawayLineItem) {
      //     let specs;
      //     if (membershipGiveawayLineItem.Variant.Specs) {
      //       specs = membershipGiveawayLineItem.Variant.Specs;
      //     } else {
      //       specs = membershipGiveawayLineItem.Specs;
      //     }
      //     orderListItem.pdmEmail = 'N/A';
      //     orderListItem.TopicTitle = 'Membership Giveaway';
      //     orderListItem.DoctorFullName = specs.V03Full_Name.Value;
      //     if (specs.Date.Value.length > 0) {
      //       orderListItem.EventDate = moment(specs.Date.Value, 'MM/DD/YYYY');
      //     }
      //     this[collection].push(orderListItem);
      //   } else {
      //     if (collection === 'approvedOrders' || collection === 'canceledOrders') { // Open or Canceled Collateral Order
      //       this.collateralOrders.push(orderListItem);
      //     }
      //   }
      // }
      orderListItem.isLoading = false;
    }
  }

  async deleteOrder(orderListItem: OrderListItem) {
    // const order: any = await this.four51Service.getOrderById(orderListItem.ID).toPromise();
    // for (const lineItem of order.LineItems) {
    //   const lineItemId = lineItem.ID;
    //   let response: any;
    //   response = await this.four51Service.deleteOrder(order.ID, lineItemId).toPromise();
    //   console.log('line item deleted');
    //   if (lineItem.Variant) {
    //     const variantId = lineItem.Variant.InteropID;
    //     const productInteropId = lineItem.Variant.ProductInteropID;
    //     response = await this.four51Service.deleteVariant(variantId, productInteropId).toPromise();
    //     console.log('variant deleted');
    //   }
    // }
    // this.unsubmittedOrders = this.unsubmittedOrders.filter(x => x.ID !== order.ID);
  }

  copyOrder(order) {
    this.modalService.open(this.copyModal).result.then((action) => {
      // copy or clone
      this.router.navigate(['/events/', {orderid: order.ID, action: action}]);
    }).catch((error) => {
      // do nothing
    });
  }

  showOrderDetails(order) {
    const modalRef = this.modalService.open(OrderDetailModalComponent, { size: 'lg', backdrop: 'static', scrollable: true });
    const orderDetailModalComponent: OrderDetailModalComponent = modalRef.componentInstance;
    orderDetailModalComponent.orderId = order.ID;
  }

  cancelOrder(order) {
    const cancellationMessage: MailerMessage = new MailerMessage();
    if (order.StatusText === 'Awaiting Approval') {
      // cancellationMessage.To = ['tom@artoftechnology.com', 'MQuinones@mdvip.com'];
      cancellationMessage.To = ['tom@artoftechnology.com'];
    } else {
      cancellationMessage.To = ['tom@artoftechnology.com',
        'skupchun@seprint.com', 'suzanne@seprint.com', 'MDVIP_CancelOrder@seprint.com'];
    }
    cancellationMessage.From = 'Marketing Support Site <MarketingSupportSite@mdvip.com>';
    cancellationMessage.Subject = 'Cancel Order: ' + order.ExternalID;
    cancellationMessage.Body = 'This message requires an email client that supports HTML email.';
    cancellationMessage.Html = '<p>Please cancel order number ' + order.ExternalID + ' within one business day.</p>';
    cancellationMessage.Html += '<p>Cancellation requested by: ' + this.mdvipUser.Email + '</p>';
    this.mailerService.sendMessage(cancellationMessage);

    const modalRef = this.modalService.open(ModalComponent);
    const modal: ModalComponent = modalRef.componentInstance;
    modal.title = 'Cancellation Request Sent';
    modal.body = 'A cancellation request has been sent for order number ' + order.ExternalID + '. ' +
      'Please allow one buisness day for the order to be removed from your order history.';
    modal.buttons = [{label: 'OK', action: '', isNeutral: true}];
  }

  isInCancelWindow(order: OrderListItem): boolean {
    let cutoffDate;
    if (order.DateSubmittedForApproval) {
      cutoffDate = order.DateSubmittedForApproval.clone().add(2, 'hours');
    } else {
      cutoffDate = order.DateCreated.clone().add(2, 'hours');
    }
    if (moment().isBefore(cutoffDate)) {
      return true;
    }
    return false;
  }
}
