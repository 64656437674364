import { Component } from "@angular/core";
import { Four51Service } from "../four51.service";

@Component({
    selector: 'app-lookup',
    templateUrl: './lookup.component.html',
    styleUrls: ['./lookup.component.scss']
  })

  export class LookupComponent {
    orderNumber = '';
    order: any = null;
    constructor(private four51Service: Four51Service) {}

    async lookupOrder() {
        // const response: any = await this.four51Service.getOrderByNumber(this.orderNumber).toPromise();
        // const orderListItem = response.List[0];
        // this.order = await this.four51Service.getOrderById(orderListItem.ID).toPromise();
        
    }

  }
